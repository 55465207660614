.pagination {
  align-self: center;
  & :global(.MuiPagination-ul) {
    gap: 0.25rem;
    & :global(.MuiButtonBase-root) {
      background: var(--on-surface-a-100);
      height: 2rem;
      border-radius: 0.375rem;
      margin: 0;

      &:global(.Mui-disabled) {
        opacity: 0.4;
      }
    }

    & :global(.MuiPaginationItem-page) {
      background: var(--on-surface-a-100);
      min-width: 2rem;
      padding: 0.5rem;
      border: 1px solid var(--colors-a-100);
      color: var(--colors-a-100);
      text-align: center;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &:hover {
        background: var(--colors-a-010);
      }
      &:active {
        background: var(--colors-a-020);
      }
      &:global(.Mui-selected) {
        background: var(--colors-a-100);
        color: var(--on-surface-a-100);

        &:hover {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.1) 0%,
              rgba(0, 0, 0, 0.1) 100%
            ),
            #3d40d5;
        }
        &:active {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.2) 0%,
              rgba(0, 0, 0, 0.2) 100%
            ),
            #3d40d5;
        }
      }
    }

    & :global(.MuiPaginationItem-previousNext) {
      width: 2rem;
      padding: 0;
      border: 1px solid var(--colors-a-020);

      &:hover {
        background: var(--colors-a-010);
      }
      &:active {
        background: var(--colors-a-020);
      }
    }
  }
}
