.wrapper {
  position: relative;
  &:global(.MuiContainer-root) {
    background-color: var(--on-surface-a-100);
    display: flex;
    flex-direction: column;
    height: 100%;
    @media (min-width: 48.0625em) {
      padding-top: var(--header-height);
    }
  }
}
.container {
  &:global(.MuiContainer-root) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 8rem;
    padding-bottom: 6rem;
    @media (min-width: 64rem) {
      padding-top: 2rem;
    }
  }
}

.tabs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & .SwipeableViews {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    & :global(.react-swipeable-view-container) {
      flex-grow: 1;
    }
  }

  & .tabpanel {
    height: 100%;
  }

  & :global(.MuiAppBar-root) {
    background-color: rgba(61, 64, 213, 0.05);
    border-radius: 0.75rem;
    box-shadow: none;
    width: 100%;
    margin: 0 auto;
    padding: 0.25rem;
  }
  & :global(.MuiTabs-root) {
    height: min-content;
    min-height: auto;
  }
  & :global(.MuiTabs-indicator) {
    display: none;
  }
  & :global(.MuiTabs-flexContainer) {
    gap: 8px;

    & :global(.MuiButtonBase-root) {
      flex-grow: 1;
      padding: 0.5rem 0.75rem;
      max-width: 100%;
      min-width: max-content;
      min-height: auto;
      height: min-content;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1.21;
      text-align: center;
      color: var(--on-surface-b-100);
      text-transform: initial;
      &:global(.Mui-selected) {
        background-color: var(--on-surface-a-100);
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
        border-radius: 0.5rem;
      }
    }
  }

  .description {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-070);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  @media (min-width: 48.0625em) {
    display: none;
  }
}

.body {
  display: none;
  @media (min-width: 48.0625em) {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1.6fr);
    flex-grow: 1;
  }
}

.main {
  display: flex;
  width: 100%;
  height: min-content;
  position: sticky;
  top: var(--header-height);
}
