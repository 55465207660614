.container {
  container-type: inline-size;
  container-name: course-details-container;

  @container course-details-container (min-width: 48.0625em) {
    & .wrapper {
      grid-template-columns: minmax(0, 1fr) 0.475fr;
      margin-top: 2rem;
    }

    & .link {
      display: block;
      margin-bottom: 1rem;
    }

    & .alumniFeedbacks {
      &__desktop {
        display: block !important;
      }
      &__mobile {
        display: none !important;
      }
    }
    & .schedule {
      &__desktop {
        display: grid !important;
      }
      &__mobile {
        display: none !important;
      }
    }
    & .requirements {
      &__desktop {
        display: grid !important;
      }
      &__mobile {
        display: none !important;
      }
    }

    & .syllabus,
    & .description,
    & .whatWeTalkAbout,
    & .whatToLearn {
      order: unset;
    }
  }
}

.link {
  display: none;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 1.5rem;
  gap: 1.5rem;
}

.content {
  display: grid;
  gap: 0;
  background-color: transparent;
  border: none;
  border-radius: 0;
  padding: 0;

  &__subtitle {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-060);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0.75rem;
    }
  }
}

.left,
.right {
  display: grid;
  gap: 1.5rem;
  grid-auto-flow: row;
  grid-auto-rows: min-content;
}

.right {
  min-width: 18.75rem;
}

.alumniFeedbacks {
  &__desktop {
    display: none !important;
  }
  &__mobile {
    display: block !important;
  }
}

.schedule {
  &__desktop {
    display: none !important;
  }
  &__mobile {
    display: grid !important;
    order: 1;
  }
}
.requirements {
  &__desktop {
    display: none !important;
  }
  &__mobile {
    display: grid !important;
    order: 2;
  }
}

.syllabus {
  order: 3;
  scroll-margin-block-start: var(--header-height);
}

.description {
  order: 4;
}

.whatWeTalkAbout {
  order: 5;
}

.whatToLearn {
  order: 6;
}
