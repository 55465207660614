.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .text {
    &:global(.MuiTypography-root) {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.18rem;
    }
  }

  .actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
  }
}
