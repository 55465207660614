.wrapper {
  display: flex;
  padding: 1rem;
  gap: 1rem;
  border-radius: 1rem;
  border: 2px solid var(--colors-a-020);
  background: var(--colors-a-005);
  align-items: center;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1 0 0;
}
.title {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-100);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.text {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-090);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@container lecture-container (max-width: 35em) {
  .wrapper {
    flex-direction: column;
  }
}

@container lecture-container (max-width: 30em) {
  .actions {
    width: 100%;
    & :global(.MuiButton-root) {
      width: 100%;
    }
  }
}
