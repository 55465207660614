.title {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-100);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    span {
      display: flex;
      gap: 0.5rem;
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (min-width: 48.0625rem) {
    gap: 2rem;
  }
}

.reviews {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (min-width: 48.0625rem) {
    gap: 1rem;
  }
}

.blockAbout {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.blockReviews {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
