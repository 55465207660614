.tableContainer {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 90svh;
  
  table {
    width: 100%;
    min-width: 720px;
    overflow: hidden;
    border-collapse: separate;
    margin-top: 2rem;
  }

  tr {
    height: 32px;
  }
  
  th,
  td {
    font-size: 0.87rem;
    line-height: 1rem;
    text-align: start;
  }
  
  th {
    font-weight: 700;
    color: #14141666;
    padding: 0.75rem;
  }
  
  td {
    font-weight: 400;
    padding: 1rem;
    border-top: 1px solid #3D40D51A;
  }

  .fail {
    color: var(--colors-b-100);
  }

  .status {
    text-transform: capitalize;
  }
}

* {
  scrollbar-width: thin;
}

