.progress {
  flex: 1;

  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      margin-bottom: 0.25rem;
    }
  }

  &__bar {
    &:global(.MuiLinearProgress-root) {
      background-color: rgba(20, 20, 22, 0.05);
      height: 0.25rem;
      border-radius: 0.625rem;
    }
    & :global(.MuiLinearProgress-bar) {
      background: var(--colors-c-100);
    }

    &_green {
      & :global(.MuiLinearProgress-bar) {
        background: var(--colors-e-100);
      }
    }
  }
}
