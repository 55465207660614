@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';

.section {
  @include section-padding;
  background-color: #fff;
}

.title {
  @include homeSectionHeading;
  &:global(.MuiTypography-root) {
    text-align: center;
    margin-bottom: 1rem;
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.imageBox {
  img {
    height: 100%;
    width: 100%;
    max-height: 235px;
    object-fit: contain;
  }
}

.itemTitle {
  &:global(.MuiTypography-root) {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;
    margin-bottom: 0.5rem;
  }
}

.itemDescription {
  &:global(.MuiTypography-root) {
    font-size: 0.87rem;
    font-weight: 400;
    line-height: 1.3rem;
    color: rgba(20, 20, 22, 0.7);
    text-align: center;
  }
}

.ctaButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  svg {
    path {
      fill: #fff;
    }
  }
}

.advantageList {
  display: none;
}

.ctaButton {
  margin-top: 1rem !important;
}

@include media('sm') {
  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .itemDescription {
    max-width: 470px;
  }
  .ctaButton {
    width: max-content;
  }
}

@include media('md') {
  .item {
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    &:first-child {
      flex-direction: row-reverse;
    }
  }
  .imageBox,
  .info {
    flex: 1;
  }
  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .itemTitle {
    &:global(.MuiTypography-root) {
      text-align: start;
    }
  }
  .itemDescription {
    max-width: 100%;
    &:global(.MuiTypography-root) {
      text-align: start;
    }
  }
}

@include media('lg') {
  .advantageList {
    display: block;
    margin-top: 0.5rem;
  }
  .imageBox {
    img {
      max-height: 490px;
    }
  }
  .itemTitle {
    &:global(.MuiTypography-root) {
      font-size: 2rem;
      line-height: 2.37rem;
    }
  }
  .itemDescription {
    &:global(.MuiTypography-root) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  .ctaButton {
    margin-top: 1.5rem;
  }
}
