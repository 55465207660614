.title {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-100);
    text-align: center;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1.5rem;

    @media (min-width: 48.0625rem) {
      font-size: 3rem;
    }
  }
}

.modified {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-060);
    text-align: center;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    margin-bottom: 1.5rem;
  }
}

.wrapper {
  padding-bottom: 1.5rem;
  @media (min-width: 48.0625rem) {
    padding-bottom: 4rem;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  * {
    margin: 0;
  }

  h2 {
    color: var(--on-surface-b-090);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    font-family: var(--biotif);
  }
  h3 {
    color: var(--on-surface-b-090);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 200%;
  }

  h4 {
    color: var(--on-surface-b-090);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }

  p {
    color: var(--on-surface-b-090);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  ul, ol {
    li {
      color: var(--on-surface-b-090);
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  a {
    color: var(--colors-a-100);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }

  & :global(.is-vertical.is-layout-flex) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
