.highlight {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.highlight::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: var(--highlight-bottom, 4px);
  height: var(--highlight-height, 4px);
  background-color: #a195ec;
  border-radius: 4px;
  z-index: -1;
  pointer-events: none;
}
