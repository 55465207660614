.wrapper {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
}

.google,
.facebook {
  flex: 1;
}
