.container {
  container-type: inline-size;
  container-name: root-container;
  position: relative;
  flex-grow: 1;
  display: flex !important;
  flex-direction: column;
}

.title {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-100);
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 1rem;
  }

  @media (min-width: 62.0625em) {
    &:global(.MuiTypography-root) {
      font-size: 2rem;
    }
  }
}

.paths {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(18.75rem, 1fr));
  position: relative;
  grid-auto-flow: row;
  grid-auto-rows: 1fr;
  align-items: start;
}
