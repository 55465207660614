.header {
  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

.summary {
  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

.details {
  &__text {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-060);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 0.375rem;
    }
  }
  &__link {
    &:global(.MuiTypography-root) {
      color: var(--colors-a-100);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-decoration: none;
      display: block;

      svg {
        display: inline-block;
        margin-right: 0.5rem;
        margin-bottom: -0.25rem;
      }

      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }
    }
  }
}

  .tasks {
    border-bottom: 1px solid var(--colors-a-010);
    border-top: 1px solid var(--colors-a-010);
  }

