@import '../../../assets/styles/mixins';

.container {
  scroll-margin-top: var(--header-height);
  &.promo {
    padding: 2.5rem 1rem;
  }
  @media (min-width: 768px) {
    &.promo {
      padding: 2.5rem 1.5rem;
    }
  }
  @media (min-width: 1024px) {
    &.promo {
      padding: 3.75rem 1.5rem 1.5rem 1.5rem;
    }
  }
}

.body {
  padding: 1.5rem;
  border-radius: 1.5rem;
  background: linear-gradient(99.45deg, #040532 -0.01%, #4c00ac 99.99%);

  &.promo {
    background: linear-gradient(
      180deg,
      hsla(0, 0%, 100%, 0.1) 0%,
      hsla(0, 0%, 100%, 0) 100%
    );
  }

  .header {
    display: flex;
    gap: 1.125rem;
    margin-bottom: 1.5rem;
    justify-content: space-between;
    align-items: center;

    .title {
      color: var(--on-surface-a-100);
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .carousel {
    margin-bottom: 1.5rem;
  }

  .footer {
    &__title {
      color: var(--on-surface-a-100);
      text-align: center;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      margin-bottom: 1.5rem;
    }
  }

  @media (min-width: 30.0625em) {
    .actions {
      margin: 0 auto;
      max-width: 22.5rem;
      &.promo {
        display: flex;
        justify-content: center;
      }
    }
  }

  @media (min-width: 35.0625em) {
    .carousel {
      margin-right: -1.5rem;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 2rem;
        background: linear-gradient(
          to left,
          #fff 0%,
          rgba(255, 255, 255, 0) 100%
        );
        right: 0;
        top: 0;
        height: 16rem;
        z-index: 1;
      }
      &.promo::after {
        background: linear-gradient(270deg, #040532 0%, rgba(4, 5, 50, 0) 100%);
      }
    }
  }

  @media (min-width: 40.0625em) {
    .carousel {
      &::after {
        width: 6.25rem;
      }
    }
  }

  @media (min-width: 62.0625em) {
    padding: 2.5rem;
    .header {
      .title {
        font-size: 2.5rem;
      }
    }
    .actions {
      min-width: 16.25rem;
      width: max-content;
    }

    .carousel {
      margin-right: -2.5rem;
    }

    .footer {
      &__title {
        font-size: 3rem;
      }
    }
  }
}
