.lecture {
  flex-direction: column;
  background: var(--on-surface-a-100);

  container-type: inline-size;
  container-name: lecture-container;

  &__mobile {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;

    .header {
      display: flex;
    }

    .player {
      margin-left: -1rem;
      margin-right: -1rem;
    }

    @media (min-width: 48em) {
      .player {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
      }
    }
    @media (min-width: 48.0625em) {
      display: none;
    }
  }

  &__desktop {
    display: none;
    @media (min-width: 48.0625em) {
      display: flex;
      flex-grow: 1;
      gap: 1rem;
      padding: 1.875rem;
      border-radius: 0.9375rem;
      box-shadow: 0px 3px 15px 0px rgba(20, 67, 114, 0.08);
    }
  }
}

.title {
  &:global(.MuiTypography-root) {
    font-weight: 700;
    font-size: 1.375rem;
    line-height: 1.22;
    color: #42444d;
  }
}

.description {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-070);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  width: 100%;

  &__prev {
    &:global(.MuiButtonBase-root) {
      margin-right: auto;
    }
  }
  &__next {
    &:global(.MuiButtonBase-root) {
      margin-left: auto;
    }
  }
}
