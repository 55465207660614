.wrapper {
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  backdrop-filter: blur(4px);
  background-color: var(--on-surface-a-060);
  z-index: 10000;
}

.section {
  position: absolute;
}
.page {
  position: fixed;
}

.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 7.5rem;
  height: 7.5rem;
  color: var(--colors-a-100);
}
.loader:before,
.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
.loader:after {
  color: var(--colors-a-100);
  opacity: 0.4;
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: 0.5em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: 0.5em 0.5em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.5em 0 0px currentcolor;
  }
  37% {
    box-shadow: -0.5em 0.5em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.5em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.5em -0.5em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.5em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.5em -0.5em 0 0 currentcolor;
  }
}
