@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';

.section {
  @include section-padding;
  background-color: #fff;
}

.box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  @include homeSectionHeading;
  &:global(.MuiTypography-root) {
    text-align: center;
    margin-bottom: 1rem;
  }
}

.description {
  &:global(.MuiTypography-root) {
    font-size: 0.87rem;
    font-weight: 400;
    line-height: 1.3rem;
    color: #374151;
    text-align: center;
    margin-bottom: 1rem;
  }
}

.ctaButton {
  all: unset;
  cursor: pointer;
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.32rem;
  color: #fff;
  background-color: #ee0000;
  margin-top: 1.5rem;
  padding: 0 1.5rem;
  box-sizing: border-box;
  transition: 0.2s ease-in;
  &:hover {
    background-color: #fa3a3a;
  }
}

@include media('sm') {
  .ctaButton {
    width: max-content;
  }
}

@include media('lg') {
  .description {
    &:global(.MuiTypography-root) {
      font-size: 1.25rem;
      line-height: 1.87rem;
      max-width: 65%;
    }
  }
}
