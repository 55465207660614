.root {
  position: relative;
  padding: 5%;
  .sectionImg {
    top: 2px;
    left: 10px;
    width: 100%;
  }
}

.imgContainer {
  position: relative;
  z-index: 2;
  overflow: hidden;
  width: 100%;
  &.sectionVar {
    aspect-ratio: 4 / 3;
  }
}

.maskedImage {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  -webkit-clip-path: url(#svgPathSection);
  clip-path: url(#svgPathSection);
}

.bgImg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  object-fit: contain;
  z-index: 1;
}
