.info {
  display: flex;
  gap: 0.75rem;
  padding: 1rem;
  border-radius: 1rem;
  background: var(--colors-a-005);

  &__highlighted {
    border: 2px solid var(--on-surface-a-020);
    .info__content {
      color: var(--on-surface-a-100);
    }
    .info__icon {
      svg {
        path {
          fill: var(--on-surface-a-100);
        }
      }
    }
  }
  &__regular {
    border: 2px solid var(--colors-a-020);

    .info__content {
      color: var(--on-surface-b-100);
    }

    .info__icon {
      svg {
        path {
          fill: var(--colors-a-100);
        }
      }
    }
  }

  &__icon {
    margin-top: -0.25rem;
    svg {
      display: flex;
    }
  }

  &__content {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
