.navigation {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  &__link {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-040);
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-decoration: none;
      text-align: center;
      position: relative;
      padding-bottom: 0.375rem;
      &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0.25rem;
        border-radius: 0.125rem;
        background-color: var(--colors-a-010);
        transition: background-color 0.3s;
      }
    }
    &:global(.MuiTypography-root.active) {
        color: var(--on-surface-b-100);

      &:after {
        background-color: var(--colors-a-100);
      }
    }
  }
}
