.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.title {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-090, rgba(20, 20, 22, 0.9));
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.list {
  margin: 0;
  padding-left: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  list-style: none;

  li {
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 4px;
      height: 4px;
      background: var(--colors-a-100);
      border-radius: 50%;
      position: absolute;
      left: -0.75rem;
      top: 0.5rem;
    }
    &:hover {
      &::before {
        background: -webkit-linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0.1) 100%
          ),
          #3d40d5;
      }
    }
    &:active {
      &:before {
        background: -webkit-linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0.2) 100%
          ),
          #3d40d5;
      }
    }
    a {
      color: var(--colors-a-100);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      text-decoration: none;

      &:hover {
        background: -webkit-linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0.1) 100%
          ),
          #3d40d5;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &:active {
        background: -webkit-linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0.2) 100%
          ),
          #3d40d5;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
