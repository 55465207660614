.container {
  &:global(.MuiContainer-root) {
    padding-top: 1rem;
    padding-bottom: 5.31rem;

    @media (min-width: 48.0625rem) {
      padding-top: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}

.body {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;

  @media (min-width: 48.0625rem) {
    grid-template-columns: minmax(21.4375rem, 0.4875fr) 1fr;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.back {
  display: none;
  @media (min-width: 48.0625rem) {
    display: block;
  }
}
