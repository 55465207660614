.modules {
  display: grid;
  gap: 0.75rem;
}

.header {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &__title {
    &:global(.MuiTypography-root) {
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.1875;
      color: var(--on-surface-b-100);
    }
  }
  &__count {
    &:global(.MuiTypography-root) {
      font-size: 0.875rem;
      line-height: 1.21;
      color: var(--on-surface-b-060);
      white-space: nowrap;
    }
  }
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    padding: 0.75rem 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid var(--colors-a-010);
    }
  }

  &__icon {
    display: flex;
    align-items: center;
  }

  &__text {
    flex-grow: 1;
    display: flex;
    gap: 0.75rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -0.15rem;
  }
  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100, #141416);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  &__duration {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-060, rgba(20, 20, 22, 0.6));
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.resources {
  display: flex;
  flex-grow: 1;

  &__item {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }
  }

  & :global(.MuiAccordionSummary-content) {
    color: var(--on-surface-b-100);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

@container course-details-container (min-width: 48.0625em) {
  .modules {
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(61, 64, 213, 0.1);
  }
}
