.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & :global(.StripeElement) {
    iframe {
      position: relative;
    }
  }
}
