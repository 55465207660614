.card {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1.25rem;
  position: relative;
  height: 100%;
  border-radius: 1rem;
  border: 1px solid var(--on-surface-b-005);
  background: var(--on-surface-a-100);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .image {
    border-radius: 0.75rem;
    overflow: hidden;
    img {
      width: 100%;
      height: auto;
      display: flex;
      aspect-ratio: 16/9;
      object-fit: cover;
    }
  }

  .title {
    &:global(.MuiTypography-root) {
      color: var(--colors-a-100);
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      min-height: 3.068125rem;
      display: flex;
      align-items: center;

      span {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }

  .duration {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-060);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-all;
    }
  }

  .list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    &__item {
      &:global(.MuiTypography-root) {
        position: relative;
        display: flex;
        gap: 0.5rem;

        span {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          color: var(--on-surface-b-100);
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          word-break: break-all;
        }

        &::before {
          content: "";
          display: block;
          flex: 0 0 0.625rem;
          height: 0.625rem;
          border-radius: 100%;
          border: 0.125rem solid var(--colors-a-020);
          margin-top: 0.3rem;
        }

        &:not(:last-child):after {
          content: "";
          display: block;
          position: absolute;
          width: 0.125rem;
          background: var(--colors-a-020);
          left: 0.2375rem;
          top: 1.2rem;
          bottom: -0.8rem;
        }
      }
    }
  }
}
