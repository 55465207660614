@import '../../assets/styles/mixins';

.body {
  .info {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    &__text {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.21rem;
      span {
        font-weight: 700;
      }
    }
  }
  .actions {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
  }
}

.modal {
  max-width: 440px;
}

.invoicesModal {
  max-width: 800px;
  overflow: hidden;
}

@include media('sm') {
  .body {
    .info {
      &__messageBox {
        max-width: 428px;
      }
    }
    .actions {
      flex-direction: row;
    }
  }
}

@include media('lg') {
  .body {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}