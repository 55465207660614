.form {
  @media (min-width: 30.0625em) {
    display: grid;
    grid-template-columns: 5.625rem auto;
    align-items: center;
    gap: 1rem;
  }
}

.wrapper {
  margin-bottom: 1rem;
  @media (min-width: 30.0625em) {
    margin-bottom: 0;
  }
}

.image {
  flex: 0 0 5.625rem;
  height: 5.625rem;
  aspect-ratio: 1;
  padding: 0.4rem;
  border-radius: 1.5rem;
  background: var(--colors-a-010);
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  svg {
    width: 100%;
    height: auto;
  }
}

.error {
  &:global(.MuiTypography-root) {
    color: var(--colors-b-100);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 0.25rem;
    text-align: center;
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  @media (min-width: 30.0625em) {
    width: fit-content;
  }

  & button,
  & label {
    flex: 0 1 calc(50% - 0.5rem);

    @media (min-width: 30.0625em) {
      flex: 0 1 max-content;
    }
  }

  & .warning {
    flex: 1 0 100%;
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-060);
      text-align: center;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      @media (min-width: 30.0625em) {
        text-align: left;
      }
    }
  }
}
