.content {
  &:global(.MuiCardContent-root) {
    padding: 0;
    align-self: center;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    @media (min-width: 48.0625em) {
      gap: 1rem;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}
