.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .text {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-090);
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.line {
  width: 100%;
  height: 0.0625rem;
  background: var(--colors-a-010);
}
