.card {
  text-decoration: none;
  color: #141416;
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 10px 12px 0px rgba(21, 23, 61, 0.07);
  transition: 0.2s ease;
  &:hover {
    box-shadow: 0px 10px 16px 0px rgba(21, 23, 61, 0.15);
  }
  display: flex;
  flex-direction: column;

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 24px;
  }
  &__footer_heading {
    color: var(--on-surface-b-070);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &__footer_text {
    color: var(--on-surface-b-100);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.image {
  border-radius: 16px;
  overflow: hidden;
  aspect-ratio: 16/9;

  img {
    width: 100%;
    height: 100%;
    display: flex;
    object-fit: cover;
    object-position: center;
  }
}

.title {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.51rem;
  text-decoration: none;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.tags {
  display: flex;
  gap: 0.25rem;
  .tag {
    font-size: 0.87rem;
    font-weight: 700;
    line-height: 1.05rem;
    padding: 0.31rem 0.5rem;
    border-radius: 34px;
    background-color: rgba(226, 227, 251, 1);
    &.recorded {
      color: #fff;
      background-color: #2ea1e8;
    }
    &.live {
      color: #fff;
      background-color: #ffa02b;
    }
  }
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  flex-grow: 1;
  gap: 0.75rem;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
