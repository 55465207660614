.navigation {
  display: flex;
  border-radius: 12px;
  background: var(--colors-a-005);
  padding: 4px;

  .link {
    padding: 8px 12px;
    text-align: center;
    flex: 1 0 0;
    border-radius: 8px;
    text-decoration: none;
    color: var(--on-surface-b-100);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    background-color: transparent;

    &.active {
      background-color: #fff;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    }
  }
}
