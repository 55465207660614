.backdrop {
  width: 100vw;
  height: 100dvh;
  z-index: 10000;
  background: #000;
  position: fixed;
  inset: 0;
  background: rgba(15, 17, 29, 0.2);
  display: grid;
  place-items: center;
}

.modal {
  width: min(calc(100vw - 32px), 472px);
  height: max-content;
  max-height: calc(100dvh - 32px);
  background-color: #fff;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .body {
    overflow-y: auto;
    flex-grow: 1;
    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px var(--on-surface-b-040);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--colors-a-100);
    }
  }

  .close {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background-color: transparent;
    svg {
      display: flex;
      width: 100%;
      height: auto;
    }

    @media (min-width: 48em) {
      right: 24px;
      top: 24px;
    }
  }
}
.image {
  aspect-ratio: 2;
  overflow: hidden;
  img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 40px 16px 16px;

  @media (min-width: 48em) {
    padding: 48px 32px 32px;
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .title {
      color: var(--on-surface-b-100);
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      text-transform: uppercase;
      font-family: var(--proxima);
    }
    .description {
      color: rgba(20, 20, 22, 0.9);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */

      .underline {
        font-weight: 800;
        -webkit-text-decoration-skip-ink: none;
        text-decoration-skip-ink: none;
        text-underline-offset: -2px;
        text-decoration-line: underline;
        text-decoration-color: #a195ec;
        text-decoration-thickness: 3px;
      }
      .timer {
        font-weight: 700;
      }
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__item {
      display: flex;
      align-items: center;
      gap: 16px;

      &_content {
        display: flex;
        flex-direction: column;

        b {
          color: var(--on-surface-b-100, #141416);
          font-family: var(--proxima);
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        span {
          color: var(--on-surface-b-070);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
        }
      }
    }
  }
}

.form {
  & :global(.hs-form-private) {
    display: flex;
    flex-direction: column;
    gap: 16px;

    & :global(.hs-main-font-element) {
      display: flex;
      flex-direction: column;
      gap: 8px;
      p {
        font-family: var(--proxima);
        text-align: center;

        &:not(:has(strong)) {
          color: rgba(20, 20, 22, 0.9);
          font-size: 16px !important;
          font-style: normal;
          font-weight: 400;
        }
        strong {
          color: var(--on-surface-b-100);
          text-align: center;
          font-size: 24px !important;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          text-transform: uppercase;
          display: inline-block;
          margin-bottom: -32px;
          vertical-align: top;
        }
      }
    }
  }

  & :global(.actions) {
    display: flex;
    justify-content: center;
    align-items: center;
    & :global(.hs-button) {
      display: flex;
      height: 48px;
      padding: 13px 24px;
      align-items: center;
      gap: 8px;
      border: none;
      border-radius: 12px;
      background-color: var(--colors-a-100);
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0.1) 100%
          ),
          var(--colors-a-100);
      }
      &:active {
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0.2) 100%
          ),
          var(--colors-a-100);
      }

      color: var(--on-surface-a-100);
      text-align: center;
      font-family: var(--proxima);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 135%;
      width: 100%;
    }
  }

  & :global(.hs-form-field) {
    display: flex;
    flex-direction: column;
    gap: 4px;
    label {
      color: var(--on-surface-b-070);
      font-family: var(--proxima);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    & :global(.input) {
      select,
      textarea {
        padding: 12px 14px;
        border-radius: 12px;
        border: 2px solid var(--colors-a-020);
        background: var(--on-surface-a-100);
        color: var(--on-surface-b-100);
        font-family: var(--proxima);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        transition: 0.3s;
        outline: none;

        &::placeholder {
          color: var(--on-surface-b-040);
          font-family: var(--proxima);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      textarea {
        resize: none;
        height: 106px;
        &:focus {
          border: 2px solid var(--colors-a-100) !important;
        }
      }
      select {
        height: 48px;
        appearance: none;
        -webkit-appearance: none;
        background-image: url(../../../assets/icons/select.svg);
        background-repeat: no-repeat;
        background-position: right 12px center;
        background-size: 24px;
      }
    }
  }
}
