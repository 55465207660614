@import '../../../../../assets/styles/mixins';

.section {
  padding: 2.5rem 0;
}

.container {
  @include container;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.heading {
  text-align: center;
}

@include media('lg') {
  .section {
    padding: 3.75rem 0;
  }
}
