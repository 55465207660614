.card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.image {
  border-radius: 1.5rem;
  overflow: hidden;
  text-decoration: none;
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 1.5rem;
    background-color: var(--colors-a-020);
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }
  img {
    width: 100%;
    height: auto;
    display: flex;
    aspect-ratio: 1.91;
    object-fit: cover;
  }
}
.title {
  &:global(.MuiTypography-root) {
    text-decoration: none;
    color: var(--colors-a-100);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    display: flex;
    align-items: center;

    &:hover {
      background: -webkit-linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.1) 0%,
          rgba(0, 0, 0, 0.1) 100%
        ),
        #3d40d5;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &:active {
      background: -webkit-linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0.2) 100%
        ),
        #3d40d5;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    span {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
.date {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-060);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    height: 1.0625rem;

    svg {
      display: inline-block;
      transform: translateY(-0.15rem);
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }
  }
}

.eventDateBox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  line-height: 0;
  .eventDate {
    font-size: 0.875rem;
    font-weight: 700;
    color: var(--on-surface-b-100);
  }
}

.excerpt {
  color: var(--on-surface-b-090);
  font-family: Proxima Nova;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 4.3125rem;

  * {
    margin: 0;
  }
}
