.rating {
  display: flex;
  align-items: center;
  gap: 0.25rem;

  & :global(.MuiRating-icon.MuiRating-iconFilled) {
    width: 1rem;
  }
  & :global(.MuiRating-root) {
    margin-top: -0.125rem;
  }

  .count {
    color: var(--on-surface-b-090);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .votes {
    color: var(--on-surface-b-040);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
