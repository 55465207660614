.chip {
  display: flex;
  height: 1.375rem;
  padding: 0.3125rem 0.5rem;
  align-items: center;
  gap: 0.375rem;
  border-radius: 0.375rem;
  background: var(--colors-d-010);

  &__text {
    &:global(.MuiTypography-root) {
      color: var(--colors-d-100);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      margin-bottom: -0.125rem;
      white-space: nowrap;

    }
  }

  &__icon {
    display: flex;
    flex: 0 0 0.75rem;
    height: auto;
  }
}
