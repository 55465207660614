@import "../../../../assets/styles/typography";
@import "../../../../assets/styles/mixins";

.section {
  @include section-padding;
  padding-top: 0;
}

.title {
  &:global(.MuiTypography-root) {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;
  }
}

.carousel {
  margin-top: 1rem;
}

.slideImage {
  height: 24px;
}

@include media("md") {
  .section {
    padding-top: 0;
  }
}

@include media("lg") {
  .title {
    &:global(.MuiTypography-root) {
      font-size: 1.5rem;
      line-height: 1.8rem;
    }
  }
}
