@import "../../../../../assets/styles/mixins";

.section {
  padding: 2.5rem 0;
}

.container {
  @include container;
  padding-right: 10px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.imageBox {
  width: 250px;
  margin: 0 auto;
  filter: drop-shadow(hsla(269, 66%, 64%, 0.8) 0 0 5rem);
}

.subHeading {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 1.5rem;

  &:empty {
    display: none;
  }
}

.richText {
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
  color: hsla(0, 0%, 100%, 0.9);
}

@include media("xs") {
  .imageBox {
    width: 300px;
  }
}

@include media("sm") {
  .imageBox {
    width: 400px;
  }
}

@include media("md") {
  .imageBox {
    width: 500px;
  }
}

@include media("lg") {
  .section {
    padding: 3.75rem 0;
  }
  .container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.5rem;
    align-items: center;
  }
  .imageBox {
    width: 450px;
  }
}

@include media("xl") {
  .subHeading {
    font-size: 2.5rem;
  }
  .imageBox {
    width: 550px;
  }
}
