.list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  gap: 0.5rem;

  &__item {
    display: flex;
    gap: 0.5rem;

    svg{
      display: flex;
      flex: 0 0 auto;
    }
  }
}
