:root {
  --neutral: #ecf0fe;
  --warning: #f6c446;
  --yellow: #f6c344;

  // new colors

  --colors-a-200: #3d40d5;
  --colors-a-100: #3f41d5;
  --colors-a-090: rgba(61, 64, 213, 0.9);
  --colors-a-040: rgba(61, 64, 213, 0.4);
  --colors-a-020: rgba(61, 64, 213, 0.2);
  --colors-a-010: rgba(61, 64, 213, 0.1);
  --colors-a-005: rgba(61, 64, 213, 0.05);

  --on-surface-a-100: #fff;
  --on-surface-a-090: rgba(255, 255, 255, 0.9);
  --on-surface-a-080: rgba(255, 255, 255, 0.8);
  --on-surface-a-070: rgba(255, 255, 255, 0.7);
  --on-surface-a-060: rgba(255, 255, 255, 0.6);
  --on-surface-a-040: rgba(255, 255, 255, 0.4);
  --on-surface-a-030: rgba(255, 255, 255, 0.3);
  --on-surface-a-020: rgba(255, 255, 255, 0.2);
  --on-surface-a-010: rgba(255, 255, 255, 0.1);

  --on-surface-b-100: #141416;
  --on-surface-b-090: rgba(20, 20, 22, 0.9);
  --on-surface-b-070: rgba(20, 20, 22, 0.7);
  --on-surface-b-060: rgba(20, 20, 22, 0.6);
  --on-surface-b-040: rgba(20, 20, 22, 0.4);
  --on-surface-b-020: rgba(20, 20, 22, 0.2);
  --on-surface-b-010: rgba(20, 20, 22, 0.1);
  --on-surface-b-005: rgba(20, 20, 22, 0.05);

  --colors-b-100: #ff3d2e;
  --colors-b-010: rgba(255, 61, 46, 0.1);
  --colors-b-020: rgba(255, 61, 46, 0.2);

  --colors-d-100: #2ea1e8;
  --colors-d-010: rgba(46, 161, 232, 0.1);

  --on-surface-c: #eaeffe;
  --on-surface-f: #fafbff;

  --flowkit-green: #29cc6a;

  --colors-c-100: #f6c344;
  --colors-e-100: #2bad28;

  --on-surface-g-100: #374151;

  --colors-p-100: #a674ef;
  --colors-p-070: rgba(61, 64, 213, 0.7);
  --colors-p-020: rgba(61, 64, 213, 0.2);
  --colors-p-010: rgba(61, 64, 213, 0.1);
  --colors-p-005: rgba(61, 64, 213, 0.05);

  --accent-gradient: linear-gradient(180deg, #3d40d5 0%, #0003ac 100%);
  --purple-gradient: linear-gradient(100.63deg, #040532 0%, #4c00ac 100%);
  --purple-gradient-light: linear-gradient(
    90.84deg,
    #aa77f1 0%,
    #2319b2 99.86%
  );
}
