.wrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.title {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-100);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.buttons {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  & :global(.react-share__ShareButton) {
    flex: 0 0 2rem;
    height: 2rem;
  }

  .copy {
    display: flex;
    flex: 0 0 2rem;
    height: 2rem;
    padding: 0.375rem;
    justify-content: center;
    align-items: center;
    border-radius: 20rem;
    background: var(--colors-a-010);
    border: none;
    outline: none;
    cursor: pointer;
  }
}
