.sidebar {
  .back {
    margin-bottom: 1.5rem;

    @media (max-width: 48em) {
      display: none;
    }
  }

  .title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 1rem;
    }
  }

  .progress {
    margin-bottom: 0.875rem;
  }
}

.module {
  border-bottom: 1px solid var(--colors-a-010);
}

.summary {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  &__subtitle {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-060);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;

  tr {
    &:not(:last-child) {
      td.link {
        border-bottom: 1px solid var(--colors-a-010);
      }
    }

    &:not(:first-child) {
      td.link {
        padding-top: 0.75rem;
      }
    }
    td.link {
      padding-bottom: 0.75rem;
    }
  }

  td {
    padding: 0;

    &.icon {
      width: 1.875rem;

      svg {
        display: inline-flex;
        vertical-align: super;
        margin-top: -0.75rem;
      }
    }

    &.link {
      a {
        &:global(.MuiTypography-root) {
          color: var(--on-surface-b-100);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 0.25rem;
          text-decoration: none;
          display: block;
        }

        &:global(.active) {
          &:global(.MuiTypography-root) {
            color: var(--colors-a-100);
          }
        }
      }
      p {
        &:global(.MuiTypography-root) {
          color: var(--on-surface-b-060);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

.resources {
  display: grid;

  &__item {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }
  }

  & :global(.MuiAccordionSummary-content) {
    color: var(--on-surface-b-100);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  & :global(.MuiAccordionSummary-root) {
    padding-top: 0.75rem;
  }
  & :global(.MuiAccordion-root) {
    border-top: 1px solid var(--colors-a-010);
  }
}
