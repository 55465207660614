@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';

.section {
  @include section-padding;
  background-color: rgba(61, 64, 213, 0.05);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  @include homeSectionHeading;
  &:global(.MuiTypography-root) {
    text-align: center;
    margin-bottom: 1rem;
  }
}

.description {
  &:global(.MuiTypography-root) {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    margin-bottom: 1rem;
    color: rgba(20, 20, 22, 0.7);
  }
}

.actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

@include media('sm') {
  .actions {
    flex-direction: row;
    justify-content: center;
  }
  .ctaButton,
  .button {
    width: max-content;
  }
}

@include media('lg') {
  .description {
    &:global(.MuiTypography-root) {
      font-size: 1.25rem;
      line-height: 1.87rem;
    }
  }
}
