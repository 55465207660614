@import "../../../../assets/styles/mixins";

.marquee {
  padding: 8px 0px;
  border-top: 1px solid var(--on-surface-a-040);
  border-bottom: 1px solid var(--on-surface-a-040);
  background: var(--on-surface-a-010);
}
.wrapper {
  background: linear-gradient(101deg, #040532 0%, #4c00ac 100%);
  margin-bottom: 2rem;
  @media (min-width: 64em) {
    margin-bottom: 3.68rem;
  }
}
.item {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 8px;
  &__text {
    color: var(--on-surface-a-100);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media (min-width: 48em) {
      font-size: 1rem;
    }
  }
  &__icon {
    svg {
      display: flex;
      width: 6px;
      height: 6px;
    }
  }
}
