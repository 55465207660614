.section {
  border-bottom: 2px solid var(--on-surface-a-020);
  background: linear-gradient(90deg, #0071ce 0%, #8321ff 100%);
  padding: 16px 0;
}

.content {
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: center;
  @media (min-width: 48em) {
    justify-content: space-between;
  }
}

.block {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.header {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;

  @media (min-width: 48em) {
    justify-content: flex-start;
  }
}

.tag {
  border-radius: 92px;
  background: #a674ef;
  padding: 5px 8px;
  height: 24px;
  color: #fff;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  width: max-content;
}

.date {
  color: #fff;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;

  @media (min-width: 48em) {
    font-size: 0.875rem;
  }
}

.title {
  text-align: center;
  margin: 0;
  @media (min-width: 48em) {
    text-align: left;
  }

  span {
    color: #fff;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
    font-size: 0.875rem;
    @media (min-width: 48em) {
      font-size: 1.25rem;
    }
  }
  a {
    color: #fff;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    white-space: nowrap;

    @media (min-width: 48em) {
      display: none;
    }
  }

  .dot {
    @media (min-width: 48em) {
      display: none;
    }
  }
}

.button {
  &:global(.MuiButton-root) {
    display: none;
    @media (min-width: 48em) {
      display: flex;
    }
  }
}
