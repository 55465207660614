@import '../../../../assets/styles/mixins';

.base {
  all: unset;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  text-decoration: none;
  cursor: pointer;
  .inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 150%;
    border-radius: 12px;
    padding: 0.75rem 1.5rem;
    border: 2px solid #fff;
    transition: 0.2s ease;
    &:hover {
      background: #3637648c;
    }
  }
  &.glow {
    &::before {
      display: block;
      content: '';
      position: absolute;
      top: -0.25rem;
      left: -0.25rem;
      right: -0.25rem;
      bottom: -0.25rem;
      z-index: -1;
      background: linear-gradient(
        to right,
        hsla(241, 100%, 50%, 1),
        hsla(281, 100%, 32%, 1),
        hsla(317, 100%, 59%, 1),
        hsla(0, 100%, 50%, 1),
        hsla(241, 100%, 50%, 1),
        hsla(281, 100%, 32%, 1),
        hsla(317, 100%, 59%, 1),
        hsla(0, 100%, 50%, 1),
        hsla(241, 100%, 50%, 1),
        hsla(281, 100%, 32%, 1),
        hsla(317, 100%, 59%, 1),
        hsla(0, 100%, 50%, 1)
      );
      filter: blur(0.5rem);
      animation: glowing 10s linear infinite;
      background-size: 350%;
      background-position: 0 0;
    }
    .inner {
      background: #040534;
      &:hover {
        background: #242553;
      }
    }
  }
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 90% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@include media('sm') {
  .base {
    width: max-content;
  }
}
