.body {
  background: var(--colors-a-010);
  border-radius: 2rem;
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: column;
}

.banner {
  border-radius: 2rem;
  background: linear-gradient(99deg, #00097d 0.01%, #ae2cbe 100%);
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  @media (min-width: 48.0625em) {
    padding: 4rem;
  }

  &__subtitle {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-a-100);
      text-align: center;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: normal;

      @media (min-width: 48.0625em) {
        font-size: 1.75rem;
        font-weight: 400;
      }
    }
  }
  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-a-100);
      text-align: center;
      font-size: 2rem;
      font-weight: 900;
      line-height: normal;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: 48.0625em) {
        font-size: 2.5rem;
      }
    }
  }

  &__date {
    display: flex;
    flex-direction: column;
    gap: 0.875rem;
    .save {
      &:global(.MuiTypography-root) {
        color: var(--on-surface-a-090);
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 151%;
        text-align: center;
      }
    }
    .date {
      display: flex;
      gap: 1rem;

      &__start {
        &:global(.MuiTypography-root) {
          color: var(--on-surface-a-100);
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 130%;
          text-align: center;
        }
      }
    }
  }
}

.opportunities {
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;

  @media (min-width: 48.0625em) {
    padding: 4rem;
  }
  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      text-align: center;
      font-size: 1.75rem;
      font-weight: 700;
      line-height: normal;

      @media (min-width: 48.0625em) {
        font-size: 2.5rem;
      }
    }
  }
  &__text {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-070);
      text-align: center;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 1rem;
    }
  }

  & .button {
    svg {
      path {
        fill: var(--on-surface-a-100);
      }
    }
  }
}
