.container {
  &:global(.MuiContainer-root) {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    container-type: inline-size;
    container-name: path-details-container;
  }

  & .wrapper {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: minmax(0, 1fr);
  }

  & .right {
    padding-top: 1.5rem;
    border-top: 1px solid var(--colors-a-010);
  }

  @container path-details-container (min-width: 48.0625em) {
    & .wrapper {
      grid-template-columns: minmax(0, 1fr) minmax(0, 0.475fr);
    }

    & .right {
      padding-top: 0;
      border-top: none;
    }
  }
}

.back {
  margin-bottom: 1rem;
}

.card {
  padding: 1rem;
  border-radius: 1rem;
  background: var(--on-surface-a-100);
  box-shadow: 0px 20px 30px 0px rgba(21, 23, 61, 0.07);
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.75rem;

  @media (min-width: 30.0625em) {
    grid-template-columns: repeat(auto-fit, minmax(24.25rem, 1fr));
  }

  @media (min-width: 48.0625em) {
    padding: 1.5rem;
    gap: 1.5rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
  }

  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 2rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  &__image {
    position: relative;
    border-radius: 1.5rem;
    overflow: hidden;
    display: flex;
    background-color: var(--on-surface-a-100);
    border: 8px solid var(--colors-a-100);
    place-self: center;

    img {
      display: flex;
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
      object-fit: cover;
    }
  }
}

.progress {
  height: min-content;

  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      margin-bottom: 0.25rem;
    }
  }

  &__bar {
    &:global(.MuiLinearProgress-root) {
      background-color: rgba(20, 20, 22, 0.05);
      height: 0.25rem;
      border-radius: 0.625rem;
    }
    & :global(.MuiLinearProgress-bar) {
      background: var(--colors-c-100);
    }

    &_green {
      & :global(.MuiLinearProgress-bar) {
        background: var(--colors-e-100);
      }
    }
  }
}

.title {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-100);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0.5rem;
  }
}

.text {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-070);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}

.left {
  container-type: inline-size;
  container-name: left-path-details-container;
}

.course {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  gap: 1rem;
  border-radius: 0.75rem;
  border: 1px solid var(--on-surface-b-005);
  background: var(--on-surface-a-100);
  padding: 1rem;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  }

  &__image {
    border-radius: 0.5rem;
    border: 2px solid var(--colors-a-090);
    overflow: hidden;

    img {
      display: flex;
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
      object-fit: cover;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  &__title {
    &:global(.MuiTypography-root) {
      color: var(--colors-a-100);
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  &__subtitle {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-060);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &__text {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-090);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }

  .htmlDescription {
    div {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }

  &__progress {
    margin-top: 0;
  }

  @container left-path-details-container (min-width: 30.0625em) {
    flex-direction: row;

    &__image {
      flex: 0 0 10.25rem;
      height: min-content;
    }

    &__content {
      gap: 0.5rem;
    }

    &__progress {
      margin-top: 0.25rem;
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0;
  padding: 0;
  list-style: none;

  &__item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: relative;
    & .marker {
      display: block;
      flex: 0 0 0.625rem;
      height: 0.625rem;
      border: 2px solid var(--colors-a-020);
      border-radius: 100%;
    }

    &_green {
      & .marker {
        border: none;
        background: var(--flowkit-green);
      }
    }
    &_orange {
      & .marker {
        border: none;
        background: var(--colors-c-100);
      }
    }

    &::before,
    &::after {
      content: "";
      display: block;
      width: 2px;
      background: var(--colors-a-020);
      height: calc(50% - 0.5rem);
      position: absolute;
      left: 0.25rem;
    }

    &::before {
      height: calc(50% - 0.5rem);
      top: 0;
    }
    &::after {
      height: 50%;
      bottom: -0.5rem;
    }

    &:first-child {
      &::before {
        display: none;
      }
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}
