.root {
  color: var(--colors-a-200) !important;
  &.promo {
    color: #fff !important;
    svg {
      path {
        fill: #fff !important;
      }
    }
  }
}
