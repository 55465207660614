.subtitle {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-070);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    a {
      color: var(--colors-a-100);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-decoration: none;
    }
  }
}
