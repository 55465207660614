.card {
  text-decoration: none;
  display: flex;
  padding: 1.25rem;
  flex-direction: column;
  gap: 0.75rem;

  border-radius: 1rem;
  border: 1px solid var(--on-surface-b-005, rgba(20, 20, 22, 0.05));
  background: var(--on-surface-a-100, #fff);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);

  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  }
}

.image {
  border-radius: 0.75rem;
  background: #c3c5ff;
  overflow: hidden;
  aspect-ratio: 1.41;
  img {
    display: flex;
    max-width: 100%;
    object-fit: cover;
    width: 100%;
    height: auto;
  }
}

.title {
  &:global(.MuiTypography-root) {
    color: var(--colors-a-100);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    height: 3rem;
    display: flex;
    align-items: center;

    span {
      vertical-align: middle;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
