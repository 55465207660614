.container {
  &:global(.MuiContainer-root) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 1rem;
  gap: 1rem;

  @media (min-width: 48.0625em) {
    gap: 1.375rem;
    padding-top: 1.5rem;
  }
}

.title {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-100);
    text-align: center;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media (min-width: 48.0625em) {
      font-size: 3rem;
    }
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-bottom: 1.5rem;
  flex-grow: 1;

  @media (min-width: 48.0625em) {
    grid-template-columns: minmax(0, 1fr) 21.4375rem;
  }

  @media (min-width: 64.0625em) {
    grid-template-columns: minmax(0, 1fr) 25.5rem;
    gap: 4rem;
    margin-bottom: 4rem;
  }
}

.posts {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__filters {
    display: block;
    width: 100%;
    margin-bottom: -0.5rem;
    @media (min-width: 48.0625em) {
      display: none;
    }
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;

  &__filters {
    display: none;
    @media (min-width: 48.0625em) {
      display: block;
    }
  }

  @media (min-width: 48.0625em) {
    height: min-content;
    position: sticky;
    top: var(--header-height);
  }
}

.empty {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-100);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    svg {
      display: inline-flex;
      margin-bottom: -0.6rem;
      margin-right: 0.5rem;
    }
  }
}
