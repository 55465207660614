.dialog {
  background: var(--colors-a-100);
  border: 1px solid var(--colors-a-100);
}

.modal {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow: hidden;
  flex-grow: 1;

  &__top {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
  }

  &__badge {
    padding: 0.125rem 0.5rem;
    background: var(--on-surface-a-100);
    color: var(--colors-a-100);
    font-weight: 700;
    border-radius: 0.75rem;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-a-100);
      font-size: 2rem;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
  }

  &__subtitle {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-a-100);
      font-weight: 700;
      line-height: normal;
      font-size: 1.75rem;
      line-height: normal;
      margin-top: -0.5rem;
    }
  }
  &__text {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-a-100);
      line-height: 130%;
    }
  }
  &__date {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-a-100);
      font-size: 1.25rem;
      font-weight: 700;
      line-height: 130%;
      display: flex;
      gap: 1rem;
    }
  }
  &__closeBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border: unset;
    outline: unset;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    margin-left: auto;

    svg {
      path {
        fill: var(--on-surface-a-100);
      }
    }
  }

  &__rules {
    flex-grow: 1;
    overflow-y: auto;
    padding-right: 0.25rem;

    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px var(--on-surface-b-040);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--colors-a-100);
    }
  }

  &__checkbox {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: max-content;
    & :global(.MuiButtonBase-root) {
      padding: 0 0 0 0.5rem;
    }
  }
}
