.wrapper {
  display: flex;
  flex-direction: column;

  .rating {
    &:global(.MuiRating-root) {
      width: max-content;
    }
    & :global(.MuiRating-icon) {
      width: 1.5rem;
    }
  }

  .label {
    color: var(--on-surface-b-070);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 0.5rem;
  }
  .error {
    display: block;
    color: var(--colors-b-100);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    font-family: var(--proxima);
    margin-left: 0.5rem;
    margin-top: 0.25rem;
  }
}
