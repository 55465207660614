.header {
  display: flex;
  justify-content: space-between;
  gap: 0.625rem;
}

.title {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-100);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }
}

.close {
  flex: 0 0 1.5rem;
  height: 1.5rem;
  background-color: transparent;
  border: 0;
  outline: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    display: block;
  }
}
