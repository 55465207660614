@import '../../../../assets/styles/mixins';

.timer {
  display: flex;
  gap: 0.8rem;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.time {
  text-align: center;
  font-size: 1rem;
  font-weight: 900;
  line-height: normal;
  font-family: Biotif, sans-serif;
}

.text {
  color: #ffffffb2;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  font-family: Biotif, sans-serif;
}

.divider {
  color: #ffffffb2;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}

@include media('xs') {
  .timer {
    gap: 1.5rem;
  }
  .timer,
  .divider {
    font-size: 1.5rem;
  }
}

@include media('md') {
  .time,
  .divider {
    font-size: 3rem;
  }
  .text {
    font-size: 0.87rem;
  }
}

@include media('lg') {
  .time,
  .divider {
    font-size: 1.5rem;
  }
  .text {
    font-size: 0.75rem;
  }
}

@include media('xl') {
  .time,
  .divider {
    font-size: 3rem;
  }
  .text {
    font-size: 0.87rem;
  }
}
