.card {
  border-radius: 1rem;
  background: var(--on-surface-a-100);
  box-shadow: 0px 10px 30px 0px rgba(21, 23, 61, 0.07);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: min-content;
  max-width: 28rem;
  margin: 0 auto;

  @media (min-width: 48.0625rem) {
    position: sticky;
    top: var(--header-height);
    left: 0;
    max-width: unset;
    margin: 0;
  }

  .image {
    flex: 0 0 11.25rem;
    height: 11.25rem;
    aspect-ratio: 1;
    padding: 0.4rem;
    border-radius: 0.75rem;
    background: var(--colors-a-010);
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }

  .title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100, #141416);
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: center;
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
      justify-content: center;

      & span:nth-child(2) {
        display: flex;
        gap: 0.5rem;
      }

      a {
        display: inline-block;
        margin-bottom: -0.35rem;
        width: 2rem;
        aspect-ratio: 1;
        align-items: center;
        justify-content: center;

        svg {
          display: block;
        }
      }
    }
  }

  .specialization {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: center;
    }
  }

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
  }
  .description {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
}
