.alumniFeedbacks {
  scroll-margin-block-start: var(--header-height);
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    margin-bottom: 1rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}

.rating {
  display: flex;
  gap: 0.5rem;

  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100, #141416);
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
