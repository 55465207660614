.wrapper {
  display: flex;
  flex-direction: column;

  .checkbox {
    &:global(.MuiFormControlLabel-root) {
      margin-right: 0;
      margin-left: 0;
      display: flex;
      gap: 0.25rem;
    }

    & :global(.MuiFormControlLabel-label) {
      color: var(--on-surface-b-100);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    & :global(.MuiButtonBase-root) {
      padding: 0;
    }
  }

  .error {
    display: block;
    color: var(--colors-b-100);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    font-family: var(--proxima);
    margin-left: 0.5rem;
    margin-top: 0.25rem;
  }
}
