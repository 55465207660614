.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  h2,
  h3,
  h4,
  p,
  ol,
  ul {
    margin: 0;
  }

  h2,
  h3,
  h4 {
    margin-bottom: -0.5rem;
    font-weight: 700;
    font-family: var(--biotif);
    color: var(--on-surface-b-100);
  }
  h2 {
    font-size: 1.25rem;
  }
  h3 {
    font-size: 1rem;
  }
  h4 {
    font-size: 0.875rem;
  }

  ol,
  ul {
    padding-left: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  &,
  a,
  div,
  span,
  p,
  ol,
  ul,
  li {
    font-size: 0.875rem;
    font-style: normal;
    line-height: 150%;
  }
  &,
  div,
  span,
  p,
  ol,
  ul,
  li {
    color: var(--on-surface-b-070);
    font-weight: 400;

    strong {
      color: var(--on-surface-b-100);
    }
  }
  a {
    font-weight: 700;
    color: var(--colors-a-100);
    text-decoration: none;
  }

  &.check {
    ol,
    ul {
      list-style-type: none;
      padding: 0;
      li {
        display: flex;
        gap: 0.5rem;
      }

      li::before {
        content: url("../../../assets/icons/check-green.svg");
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }

  &.regular {
    &,
    a,
    div,
    span,
    p,
    ol,
    ul,
    li {
      font-size: 1rem;
    }
  }

  &.dark-060 {
    &,
    div,
    span,
    p,
    ol,
    ul,
    li {
      color: var(--on-surface-b-060);
    }
  }
  &.dark-090 {
    &,
    div,
    span,
    p,
    ol,
    ul,
    li {
      color: var(--on-surface-b-090);
    }
  }

  pre {
    display: flex;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: #2a2a2a;
    width: 100%;
    position: relative;
    margin: 0;
    & code {
      width: 100%;
      color: var(--on-surface-a-100);
      font-family: monospace;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      overflow-x: auto;
      padding-bottom: 0.5rem;
      margin-bottom: -0.5rem;
      padding-right: 1.5rem;

      &::-webkit-scrollbar {
        height: 2px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 1px var(--on-surface-a-040);
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--on-surface-a-100);
      }
    }

    & :global(.html-code-copy-button) {
      cursor: pointer;
      width: 3rem;
      height: 3rem;
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.75rem;
      border-radius: 0.75rem;
      background: var(--on-surface-a-100);

      &:hover {
        background: linear-gradient(
            0deg,
            rgba(49, 51, 170, 0.1) 0%,
            rgba(49, 51, 170, 0.1) 100%
          ),
          #fff;
      }
      &:active {
        background: linear-gradient(
            0deg,
            rgba(49, 51, 170, 0.2) 0%,
            rgba(49, 51, 170, 0.2) 100%
          ),
          #fff;
      }
    }
  }
}
