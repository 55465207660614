.swiper {
  padding: 24px 0 24px 10px;
  margin: -24px -16px;

  @media (min-width: 48em) {
    padding: 24px 0 24px 24px;
    margin: -24px;
  }

  @media (min-width: 90em) {
    padding: 24px8;
  }
}

.slide {
  height: auto;
  flex: 0 0 300px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  aspect-ratio: 16/9;

  img {
    display: flex;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 48em) {
    flex: 0 0 448px;
  }

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("../../../../../assets/images/youtube-red.svg");
    background-repeat: no-repeat;
    background-size: 4rem;
    background-position: center;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 20px;
    background-color: var(--colors-a-020);
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
