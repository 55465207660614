.button {
  &:global(.MuiFormControlLabel-root) {
    display: flex;
    padding: 1rem;
    gap: 0.75rem;
    border-radius: 16px;
    border: 1px solid var(--on-surface-b-005);
    background-color: var(--on-surface-a-100);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    margin: 0;
    &:global(.Mui-disabled) {
      opacity: 0.4;
    }
    & :global(.MuiButtonBase-root) {
      padding: 0;
    }
    & :global(.MuiTypography-root) {
      flex-grow: 1;
    }
  }
}
