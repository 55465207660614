.body {
  max-width: 57.4375rem;
  margin: 0 auto;
  display: grid;
  gap: 1.5rem;
}

.title {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-100);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-size: 1.75rem;
    text-align: center;

    @media (min-width: 48.0625em) {
      font-size: 2.5rem;
    }
  }
}

.filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.25rem;
}

.mentors {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.empty {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-100);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;

    svg {
      display: inline-block;
      margin-bottom: -0.6125rem;
    }
  }
}
