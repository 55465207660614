.body {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media (min-width: 48.0625em) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.5rem;
    align-items: center;
  }
}

.title {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-100);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &__desktop {
    &:global(.MuiTypography-root) {
      display: none;
      margin-bottom: 0.75rem;
      font-size: 2.5rem;

      @media (min-width: 48.0625em) {
        display: block;
      }
    }
  }
  &__mobile {
    &:global(.MuiTypography-root) {
      font-size: 1.75rem;
      order: 1;

      @media (min-width: 48.0625em) {
        display: none;
      }
    }
  }
}

.content {
  order: 3;

  .text {
    &:global(.MuiTypography-root) {
      margin-bottom: 1.5rem;
      color: var(--on-surface-b-100);
      font-feature-settings: "clig" off, "liga" off;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.6875rem;
    }
  }

  .actions {
    max-width: 22.5rem;
    margin: 0 auto;
  }

  @media (min-width: 48.0625em) {
    order: 1;
    .actions {
      width: max-content;
      margin: 0;
    }
  }
}

.image {
  display: flex;
  height: 13.3125rem;
  padding: 0.44rem 2rem 0;
  justify-content: center;
  border-radius: 2rem;
  background: linear-gradient(180deg, #3d40d5 0%, #0003ac 100%);
  overflow: hidden;
  order: 2;

  img {
    max-width: 16.34375rem;
    display: flex;
    margin-bottom: -6%;
    object-fit: contain;
  }

  @media (min-width: 48.0625em) {
    min-height: 22.5rem;
    padding: 0rem 4rem;
    align-items: center;
    height: 100%;
    img {
      margin-bottom: -15%;
      max-width: unset;
    }
  }
}
