.body {
  border-radius: 1.5rem;
  background: linear-gradient(261deg, #8146ff 0.42%, #020033 100%);
  padding: 2rem 1.5rem;
  overflow: hidden;

  @media (min-width: 62.0625em) {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: 1.5rem;
    align-items: center;
    padding: 2.5rem;
  }
  .content {
    .title {
      color: var(--on-surface-a-100);
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 1rem;
    }

    .list {
      margin: 0 0 1.5rem 0;
      padding: 0;
      list-style: none;
      & .item {
        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }

        &__icon {
          display: flex;
          margin-bottom: 0.75rem;
        }
        &__title {
          color: var(--on-surface-a-100);
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 0.5rem;
        }
        &__text {
          color: var(--on-surface-a-060);
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }

    @media (min-width: 30.0625em) {
      .actions {
        max-width: 22.5rem;
        margin: 0 auto;
      }

      .list {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 2rem;
        & .item {
          &:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }

    @media (min-width: 48.0625em) {
      .title {
        margin-bottom: 1.5rem;
      }
    }

    @media (min-width: 62.0625em) {
      grid-column: span 6;
      margin-bottom: 0;

      .title {
        font-size: 2.5rem;
      }

      .actions {
        max-width: unset;
      }
    }
  }
  .image {
    display: none;
    @media (min-width: 62.0625em) {
      grid-column: span 6;
      height: 100%;
      width: 100%;
      display: block;
      background-image: url(./images/discord.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: top right 25%;
      height: calc(100% + 5rem);
      width: calc(100% + 3.5rem);
      margin-left: -2.5rem;
    }
  }
}
