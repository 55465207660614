.link {
  text-decoration: none;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  padding: 0.87rem 1.5rem;
  color: var(--colors-a-200);
  transition: 0.2s ease;
  &.promo {
    color: #fff !important;
    &:hover {
      border-radius: 12px;
      background-color: var(--on-surface-a-010);
    }
  }
}
