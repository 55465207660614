@import '../../../../assets/styles/mixins';

.heading {
  font-size: 1.75rem;
  line-height: 150%;
  font-weight: 900;
}

@include media('md') {
  .heading {
    font-size: 3rem;
  }
}

@include media('lg') {
  .heading {
    font-size: 1.75rem;
  }
}

@include media('xl') {
  .heading {
    font-size: 3rem;
  }
}
