.header {
  &:global(.MuiCardHeader-root) {
    padding: 0;

    & :global(.MuiCardHeader-avatar) {
      max-width: 100%;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      gap: 0.375rem;
      margin-right: 0;
    }
  }
}
