.body {
  .content {
    padding: 1.5rem;
    border-radius: 1rem;
    background: var(--on-surface-a-100);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  
    &:first-child {
      margin-top: 1rem;
    }
  
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  
    &__title {
      color: var(--on-surface-b-100);
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 1rem;
    }
  }
}