.text {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-070);
    font-family: Proxima Nova;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
