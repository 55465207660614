.list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 1rem;

  @media (max-width: 1100px) {
    grid-template-columns: 1fr;
    max-width: 460px;
    margin: 0 auto;
  }
}
