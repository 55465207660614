.deskText {
  display: none;
}

@media (min-width: 1024px) {
  .mobText {
    display: none;
  }
  .deskText {
    display: inline-block;
  }
}
