.root {
  color: #fff;
  background-color: #040534;
  flex-grow: 1;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: Biotif, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
p,
a,
ul,
li {
  margin: 0;
  line-height: 150%;
}
