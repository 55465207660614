@import "../../../../../assets/styles/mixins";

.section {
  padding-top: 6rem;
  padding-bottom: 1.5rem;
  background: linear-gradient(
    180deg,
    hsl(32, 100%, 3%) 0%,
    hsl(259, 76%, 25%) 50.29%,
    hsl(239, 85%, 11%) 100%
  );
}

.container {
  @include container;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.badge {
  width: max-content;
  font-size: 16px;
  font-weight: 900;
  color: #3f41d5;
  padding: 8px 12px;
  background-color: #fff;
  border-radius: 92px;
  line-height: 1;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.heading {
  font-size: 2rem;
  font-weight: 900;
  text-align: center;
  line-height: normal;
}

.subHeading {
  color: #ffffff99;
  font-size: 1.75rem;
  font-weight: 300;
  line-height: normal;
  text-align: center;
}

.dateText {
  font-size: 1rem;
  font-weight: 700;
  line-height: 150%;
  text-align: center;
}

.imageBox {
  img {
    max-width: 100%;
    height: auto;
    display: flex;
  }
}

.smallText {
  font-size: 1rem;
  font-weight: 700;
  color: #ffffffb2;
  text-align: center;
  margin-bottom: 1rem;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@include media("md") {
  .heading {
    font-size: 2.5rem;
  }
  .dateText {
    font-size: 1.25rem;
  }
  .imageBox {
    img {
      height: 468px;
      max-width: auto;
    }
  }
}

@include media("lg") {
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .imageBox {
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .info {
    align-items: flex-start;
  }
  .heading {
    font-size: 2rem;
    text-align: start;
  }
  .subHeading {
    text-align: start;
  }
  .dateText {
    font-size: 1rem;
    text-align: start;
  }
  .smallText {
    margin-bottom: 0;
  }
}

@include media("xl") {
  .heading {
    font-size: 2.5rem;
  }
  .dateText {
    font-size: 1.25rem;
  }
}
