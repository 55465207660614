.modules {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding-top: 0.75rem;
}

.header {
  flex-grow: 1;
  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

.summary {
  &:global(.MuiAccordionSummary-root) {
    padding-left: 0;
  }

  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100, #141416);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

.details {
  margin-left: -2rem;

  &__header {
    display: flex;
    gap: 0.75rem;
    margin-bottom: 0.75rem;

    img {
      display: flex;
      flex: 0 0 1rem;
    }
  }

  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-090, rgba(20, 20, 22, 0.9));
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  &__list {
    margin: 0;
    padding: 0 0 0 1.5rem;

    li {
      &:not(:last-child) {
        margin-bottom: 0.375rem;
      }
    }
  }

  &__content {
    padding-left: 1.75rem;
  }
}

@container course-details-container (min-width: 48.0625em) {
  .modules {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid rgba(61, 64, 213, 0.1);
  }
}

.record {
  margin-top: 0.625rem;
  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 0.5rem;
    }
  }

  &__link {
    text-decoration: none;
    &:global(.MuiTypography-root) {
      color: var(--colors-a-100);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    svg {
      display: flex;
    }
  }
}

.info {
  display: flex;
  padding: 0.75rem;
  gap: 0.875rem;
  border-radius: 0.75rem;
  border: 1px solid var(--on-surface-b-020);

  &__icon {
    display: flex;
    flex: 0 0 1.5rem;
  }
}
