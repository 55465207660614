.tag {
  padding: 0.3125rem 0.75rem;
  border-radius: 62.5rem;
  background: var(--on-surface-c);
  color: var(--colors-a-100);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
