.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0.5rem;
  &__text {
    &:global(.MuiTypography-root) {
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: var(--on-surface-b-100);
      white-space: nowrap;

      svg {
        display: inline-block;
        margin-bottom: -0.125rem;
        margin-right: 0.75rem;
      }
    }
  }
  tr {
    &:last-child {
      td {
        border-bottom: none;
      }
    }
    td {
      vertical-align: top;
      border-bottom: 0.0625rem solid rgba(61, 64, 213, 0.1);
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      &:nth-child(1) {
        white-space: nowrap;
        padding-right: 2rem;

        span {
          display: block;
          margin-left: 1.75rem;
          color: var(--on-surface-b-060);
          font-family: Proxima Nova;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      &:nth-child(2) {
        width: 100%;
      }
    }
  }
}
