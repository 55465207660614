.scrolled {
  &.promo {
    background: hsl(239, 85%, 11%) !important;
  }
}

.box {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.toolbar {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.burger {
  padding-right: 0.7rem;
  margin-left: 0.8rem;
}

.innerBurger {
  min-width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: var(--colors-a-200);
  &.auth {
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid var(--colors-a-200);
    svg {
      path {
        stroke: var(--colors-a-200);
      }
    }
    &.promo {
      border: 1px solid transparent;
    svg {
      path {
        stroke: #fff;
      }
    }
    }
  }
  &.promo {
    background-color: transparent;
    transition: 0.2s ease;
    &:hover {
      border-radius: 12px;
      background-color: var(--on-surface-a-010);
    }
  }
}

.navbar {
  display: none;
}

.discord {
  display: none;
}

.authButton {
  display: none;
}

@media (min-width: 375px) {
  .authButton {
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .discord {
    display: flex;
  }
}

@media (min-width: 1440px) {
  .navbar {
    display: flex;
    gap: 1rem;
  }
}
