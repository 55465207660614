.label {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  align-items: center;
  &__name {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    p {
      margin: 0;
      margin: 0;
      color: var(--on-surface-b-100);
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }
  }
  &__price {
    margin: 0;
    color: var(--on-surface-b-100);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
