.body {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    margin-bottom: 1rem;
    display: flex;
    gap: 1rem;
    justify-content: space-between;

    &__button {
      padding: 0.75rem;
      flex: 0 0 3rem;
      min-width: 3rem;

      @media (min-width: 48.0625em) {
        display: none;
      }
    }
  }

  .title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      font-size: 2rem;
    }
  }
  .content {
    flex: 1;

    @media (min-width: 48.0625em) {
      display: grid;
      gap: 1.5rem;
      grid-template-columns: minmax(0, 1fr) min(35%, 22.3125rem);
    }
  }

  .rating {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .position {
      &:global(.MuiTypography-root) {
        color: var(--on-surface-b-060);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        a {
          color: var(--colors-a-100);
          font-weight: 700;
          text-decoration: none;
        }
      }
    }

    .list {
      flex-grow: 1;
      min-width: min-content;
      display: grid;
      grid-auto-rows: min-content;
      grid-template-columns:
        [column-1] 0.171fr [column-2] 2.25rem
        [column-3] auto [column-4] 0.32122fr;
      column-gap: 0.5rem;
      @media (min-width: 48.0625em) {
        grid-template-columns:
          [column-1] 0.171fr [column-2] 2.75rem
          [column-3] auto [column-4] 0.32122fr;
        column-gap: 1.5rem;
      }
      &__item {
        grid-column: span 4;
        display: grid;
        grid-template-columns: subgrid;
        padding: 0.5rem 1rem;
        border-bottom: 0.0625rem solid var(--colors-a-010);
        text-decoration: none;
        margin-left: -1rem;
        margin-right: -1rem;

        @media (min-width: 48.0625em) {
          padding: 0.5rem 1.5rem;
          margin-left: 0;
          margin-right: 0;
        }

        &_active {
          background: var(--colors-a-005);
        }

        &:hover {
          background: var(--colors-a-010);
        }
        &:active {
          background: var(--colors-a-020);
        }

        &:first-child {
          border-top: 0.0625rem solid var(--colors-a-010);
          & .place {
            &:global(.MuiTypography-root) {
              color: #ffba02;
            }
          }
        }

        &:nth-child(2) {
          & .place {
            &:global(.MuiTypography-root) {
              color: #b7b7b7;
            }
          }
        }
        &:nth-child(3) {
          & .place {
            &:global(.MuiTypography-root) {
              color: #d96540;
            }
          }
        }

        & .place {
          &:global(.MuiTypography-root) {
            place-self: center;
            color: var(--on-surface-b-060);
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

            @media (min-width: 48.0625em) {
              font-size: 1rem;
            }
          }
        }
        & .image {
          flex: 0 0 2.25rem;
          height: 2.25rem;
          aspect-ratio: 1;
          padding: 0.4rem;
          border-radius: 0.75rem;
          background: var(--colors-a-010);
          overflow: hidden;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          @media (min-width: 48.0625em) {
            flex: 0 0 2.75rem;
            height: 2.75rem;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
          }

          svg {
            width: 100%;
            height: auto;
          }
        }
        & .name {
          &:global(.MuiTypography-root) {
            align-self: center;
            color: var(--on-surface-b-100, #141416);
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            word-break: break-all;

            @media (min-width: 48.0625em) {
              font-size: 1rem;
              margin-left: -0.5rem;
            }
          }
        }
        & .points {
          &:global(.MuiTypography-root) {
            align-self: center;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            white-space: nowrap;

            @media (min-width: 48.0625em) {
              font-size: 1rem;
            }
            span {
              color: var(--on-surface-b-060);
            }
          }
        }
      }
    }
  }

  .rules {
    display: none;

    @media (min-width: 48.0625em) {
      display: block;
      position: sticky;
      top: var(--header-height);
      left: 0;
      height: min-content;
      &__title {
        &:global(.MuiTypography-root) {
          color: var(--on-surface-b-100);
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          font-size: 1.5rem;
          margin-bottom: 0.5rem;
        }
      }
      &__text {
        &:global(.MuiTypography-root) {
          color: var(--on-surface-b-070);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
    }
  }
}

.MenuItem {
  padding: 0.75rem !important;
  color: var(--on-surface-b-100);

  &:global(.Mui-selected) {
    background: var(--colors-a-005) !important;
  }
  &:hover {
    background: var(--colors-a-010) !important;
  }
  &:active {
    background: var(--colors-a-020) !important;
  }

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.select {
  max-width: calc(100vw - 2rem);

  & :global(.MuiSelect-select) {
    padding-left: 0.875rem;
    padding-right: 2.5rem !important;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-radius: 0.75rem;
    min-height: unset !important;

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  & :global(.MuiInputBase-root) {
    background-color: var(--on-surface-a-100);

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  & :global(.MuiSvgIcon-root) {
    margin-right: 0.25rem;
    path {
      fill: var(--colors-a-100);
    }
  }
}

.menuPaper {
  border-radius: 0.75rem !important;
  background: var(--on-surface-a-100) !important;
  box-shadow: 0px 20px 30px 0px rgba(21, 23, 61, 0.07) !important;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px var(--on-surface-b-040);
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--colors-a-100);
  }
}

.modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
  flex-grow: 1;

  &__header {
    display: flex;
    gap: 0.62rem;
  }
  &__title {
    flex-grow: 1;
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }
  }
  &__closeBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border: 0;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
  }

  &__rules {
    flex-grow: 1;
    overflow-y: auto;
    padding-right: 0.25rem;

    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px var(--on-surface-b-040);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--colors-a-100);
    }
  }
}
