.badge {
  &:global(.MuiTypography-root) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    padding: 1rem;
    min-width: max-content;
    white-space: nowrap;
    border-radius: 1rem;
    border: 2px solid var(--colors-a-010);
    background: var(--on-surface-a-100);
    height: 3rem;

    span {
      color: var(--on-surface-b-100, #141416);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    @media (min-width: 48.0625em) {
      font-size: 1rem;
      height: 3.375rem;
      gap: 1rem;
    }
  }
}
