.wrapper {
  padding-bottom: 2.5rem;
  background-color: #fafbff;
  padding-top: 0.75rem;
  height: 100%;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  container-type: inline-size;
  container-name: certificate-details-container;

  .content {
    display: grid;
    gap: 1.5rem;

    @container certificate-details-container (min-width: calc(48.0625em - 2rem)) {
      grid-template-columns: auto 18.75rem;
      gap: 1.375rem;
    }

    @container certificate-details-container (min-width: calc(62.0625em - 2rem)) {
      grid-template-columns: auto 22.3125rem;
    }
  }

  .certificate {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    container-type: inline-size;
    container-name: certificate-details-certificate-container;

    &__image {
      border-radius: 1.72056rem;
      border: 2px solid var(--colors-a-100);
      background: var(--on-surface-a-100);
      overflow: hidden;

      img {
        display: block;
        max-width: 100%;
        width: 100%;
        height: auto;
        aspect-ratio: 1.41;
      }
    }

    &__actions {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @container certificate-details-certificate-container (min-width: 30em) {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  .sidebar {
    container-type: inline-size;
    container-name: certificate-details-sidebar-container;
    &__content {
      display: grid;
      grid-template-columns: 1fr;

      gap: 1.5rem;

      @container certificate-details-sidebar-container (min-width: calc(40.0625em - 2rem)) {
        grid-template-columns: auto 18.75rem;
      }

      @container certificate-details-sidebar-container (min-width: calc(48.0625em - 2rem)) {
        grid-template-columns: auto 22.3125rem;
      }
    }
  }

  .user {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &__title {
      &:global(.MuiTypography-root) {
        color: var(--on-surface-b-100);
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    &__card {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
    &__image {
      border-radius: 0.75rem;
      overflow: hidden;

      img {
        width: 2.75rem;
        aspect-ratio: 1;
        display: block;
      }
    }
    &__name {
      &:global(.MuiTypography-root) {
        color: var(--on-surface-b-100);
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }

  .course {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    &__title {
      &:global(.MuiTypography-root) {
        color: var(--on-surface-b-100);
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}
