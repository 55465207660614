.form,
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (max-width: 48em) {
    height: 100%;
  }
}

.link {
  color: inherit;
}

.button {
  @media (max-width: 40.0625em) {
    margin-top: auto !important;
  }
}

.title {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-100);
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 0.5rem;
    margin-bottom: -0.75rem;
  }
}

.subtitle {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-060);
    text-align: center;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0.5rem;
  }
}

.discord {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-070);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 0.25rem;
  }
  &__link {
    &:global(.MuiTypography-root) {
      color: var(--colors-a-100, #3d40d5);
      text-decoration: none;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

.phone {
  &__error {
    & :global(.form-control) {
      border-color: var(--colors-b-020) !important;

      &:focus {
        border-color: var(--colors-a-100) !important;
      }
    }
  }

  &:global(.react-tel-input) {
    & :global(.special-label) {
      display: block;
      color: var(--on-surface-b-070);
      font-family: var(--proxima);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      background: transparent;
      position: static;
      padding: 0;
      margin-left: 0.5rem;
      margin-bottom: 0.1rem;
      margin-top: -1px;
    }

    & :global(.form-control) {
      padding: 0.75rem 0.875rem 0.75rem 2.875rem;
      width: 100%;
      height: 3rem;
      border-radius: 0;
      border-radius: 0.75rem;
      border: 2px solid var(--colors-a-020);
      background: var(--on-surface-a-100);
      font-family: var(--proxima);
      color: var(--on-surface-b-100, #141416);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &:hover,
      &:focus,
      &:active {
        border-color: var(--colors-a-100);
      }

      &::placeholder {
        color: var(--on-surface-b-040);
        font-family: var(--proxima);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 1;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
      &:-webkit-autofill:hover {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
      &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
      }
    }

    & :global(.flag-dropdown) {
      height: 3rem;
      top: unset;
      border-radius: 0.75rem 0 0 0.75rem !important;
      border: none;
      background-color: transparent !important;
      &:hover,
      &:active {
        background-color: transparent;
      }

      & :global(.selected-flag) {
        cursor: pointer;
        padding: 0 0 0 0.875rem !important;
        width: 2.875rem;
        border: none;
        background-color: transparent !important;
        border-radius: 0.75rem 0 0 0.75rem !important;
        &:hover,
        &:active {
          background-color: transparent;
        }

        & :global(.flag) {
          transform: scale(1.5);
          transform-origin: left center;
          margin-top: -0.375rem;
        }
      }
      & :global(.arrow) {
        display: none;
      }

      & :global(.country-list) {
        border-radius: 0.75rem;
        background: var(--on-surface-a-100);
        box-shadow: 0px 4px 16px 0px rgba(4, 44, 91, 0.24);

        &::-webkit-scrollbar {
          width: 2px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 1px var(--on-surface-b-040);
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--colors-a-100);
        }

        & :global(.country) {
          padding: 0.75rem;
          font-family: var(--proxima);
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          &:hover,
          &:global(.highlight) {
            background: var(--colors-a-010);
          }
          & :global(.flag) {
            transform: scale(1.25);
            transform-origin: left center;
            margin-right: 1rem;
          }
          & :global(.country-name) {
            color: var(--on-surface-b-100);
            margin-right: 0.5rem;
          }
          & :global(.dial-code) {
            color: var(--on-surface-b-060);
          }
        }
      }
    }
  }
}
