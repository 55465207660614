.filter {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding: 0.3125rem 0.75rem;
  white-space: nowrap;
  border-radius: 0.75rem;
  border: 1px solid var(--colors-a-100);
  background: var(--on-surface-a-100);
  color: var(--colors-a-100, #3d40d5);
  text-align: center;
  font-family: var(--proxima);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  cursor: pointer;

  &:hover {
    background: var(--colors-a-010);
  }

  svg {
    display: none;
  }

  &_active {
    background: var(--colors-a-010);
    svg {
      display: flex;
    }
  }
}
