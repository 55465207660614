.container {
  &:global(.MuiContainer-root) {
    background: var(--on-surface-f);
    flex: 1;
    display: flex;
    flex-direction: column;

    @media (max-width:40.0625rem) {
      background: var(--on-surface-a-100);
    }
  }
}

.wrapper {
  &:global(.MuiContainer-root) {
    padding-top: 1.5rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @media (max-width:40.0625rem) {
      padding-bottom: 1rem;
      padding-top: 1rem;
    }
  }
}

.body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  align-items: center;
}

.layout {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 20.625rem;
  background-image: url(./img/layout.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1rem;

  @media (max-width:40.0625rem) {
    display: none;
  }
}
.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 31.625rem;
  width: 100%;
  position: relative;
  z-index: 1;

  @media (max-width:40.0625rem) {
    max-width: unset;
  }
}
.header {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
  display: flex;
  justify-content: center;

  @media (max-width:40.0625rem) {
    display: none;
  }
}
.main {
  flex-grow: 1;
}
.card {
  padding: 3rem;
  border-radius: 1rem;
  background: var(--on-surface-a-100);
  box-shadow: 0px 20px 30px 0px rgba(21, 23, 61, 0.07);
  @media (max-width:40.0625rem) {
    box-shadow: none;
    height: 100%;
    padding: 0;
  }
}
.footer {
  margin: 2rem auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.75rem;
  max-width: 22rem;

  @media (max-width:40.0625rem) {
    display: none;
  }

  svg{
    max-width: 9.25rem;
    width: 100%;
    height: auto;
    display: flex;
  }

  & .policy {
    color: var(--on-surface-b-060);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    &__link {
      color: var(--colors-a-100);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      text-decoration: none;
    }
  }

  & .rights {
    color: var(--on-surface-b-040);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
}
