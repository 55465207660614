.body {
  padding: 2rem 1.5rem 0 1.5rem;
  border-radius: 2rem;
  background-image: url(../images/bg-mobile.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  overflow: hidden;

  @media (min-width: 48.0625em) {
    background-image: url(../images/bg.png);
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: 1.5rem;
    padding: 0;
    align-items: center;
  }
}

.content {
  margin-bottom: 0.5rem;
  .title {
    color: var(--on-surface-a-100);
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0.75rem;
    text-align: center;
  }
  .subtitle {
    color: var(--on-surface-a-100);
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 1rem;
    text-align: center;
  }
  .list {
    margin: 0 auto 1.5rem auto;
    padding-left: 1.5rem;
    width: fit-content;

    &__item {
      color: var(--on-surface-a-070);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.2;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }

  .actions {
    max-width: 22.5rem;
    margin: 0 auto;
    &__start {
      width: 100%;
    }
  }

  @media (min-width: 48.0625em) {
    margin-bottom: 0;
    grid-column: span 6;
    padding: 2rem 0 2rem 2rem;

    .title {
      text-align: left;
      margin-bottom: 1rem;
    }
    .subtitle {
      text-align: left;
    }
    .list {
      width: 100%;
      margin-bottom: 2rem;
    }

    .actions {
      max-width: unset;
      &__start {
        width: max-content;
      }
    }
  }

  @media (min-width: 64.0625em) {
    padding-right: 0rem;
    padding-left: 3rem;
  }

  @media (min-width: 72.0625em) {
    .title {
      font-size: 3.5rem;
    }

    .subtitle {
      font-size: 2.5rem;
    }

    .list {
      &__item {
        font-size: 1.25rem;
      }
    }
  }

  @media (min-width: 80.0625em) {
    padding: 4rem 0 4rem 4rem;
  }
}

.image {
  max-width: 22.5rem;
  width: 100%;
  margin: 0 auto;

  &__wrapper {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-bottom: -12%;
    overflow: hidden;

    img {
      max-width: 100%;
      width: 100%;
      height: auto;
      display: flex;
    }
  }

  @media (min-width: 48.0625em) {
    grid-column: span 6;
    max-width: unset;
    margin: 0;
    display: flex;
    align-items: center;
    height: 100%;
    &__wrapper {
      margin: 0 0 -12% -1.5rem;
      flex: 1 1 100%;

      img {
        max-width: 100%;
        height: auto;
        object-fit: cover;
        object-position: left top;
      }
    }
  }
}
