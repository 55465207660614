.modal {
  border-radius: 1rem;
  border: 1px solid #eaeffe;
  background: var(--on-surface-a-100);
  box-shadow: 0px 20px 30px 0px rgba(21, 23, 61, 0.07);
  padding: 1rem;

  &[open] {
    display: flex;
    flex-direction: column;
  }

  max-width: min(calc(100% - 6px - 2em), 37.5rem);
  width: 100%;

  @media (min-width: 48.0625em) {
    padding: 2rem;
  }

  &::backdrop {
    background-color: rgba(255, 255, 255, 0.4);
  }

  &::-webkit-scrollbar {
    width: 2px;
    border-radius: 1rem;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px var(--on-surface-b-040);
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--colors-a-100);
    border-radius: 1rem;
  }
}
