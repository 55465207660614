.review {
  display: flex;
  gap: 0.75rem;

.image {
  flex: 0 0 2.75rem;
  height: 2.75rem;
  padding: 0.4rem;
  border-radius: 0.75rem;
  background: var(--colors-a-010);
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  svg {
    width: 100%;
    height: auto;
  }
}

  .feedback {
    max-height: 12.5rem;
    overflow-y: auto;
    padding-right: 0.5rem;
    padding-top: 0.125rem;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px var(--on-surface-b-040);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--colors-a-100);
    }
  }

  .content {
    .title {
      &:global(.MuiTypography-root) {
        color: var(--on-surface-b-100);
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 0.375rem;
      }
    }

    .text {
      margin-top: 0.5rem;
      & * {
        margin: 0;
        color: var(--on-surface-b-070) !important;
        font-size: 0.875rem !important;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }
}
