.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;

  &__text {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    img {
      display: inline-block;
      margin-right: 0.5rem;
      margin-bottom: -0.15rem;
    }
  }

  &__link {
    color: var(--colors-a-100);
    font-family: var(--proxima);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    svg {
      display: inline-block;
      margin-right: 0.25rem;
      margin-bottom: -0.15rem;
    }
  }
}

.support {
  flex: 1;
  &:global(.MuiTypography-root) {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 0.5rem;

    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    svg {
      display: flex;
      flex: 0 0 1rem;
      height: auto;
    }
  }
}
