.wrapper {
  padding: 2rem 1.5rem;

  @media (min-width: 48.0625em) {
    padding: 3.75rem 1.5rem;
  }
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  gap: 2rem;
  justify-items: center;

  @media (min-width: 48.0625em) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    max-width: 72.25rem;
    margin: 0 auto;

    &__item {
      max-width: 20.625rem;
      display: flex;
    }
  }
}
