.chip {
  display: flex;
  height: 1.375rem;
  padding: 0.3125rem 0.5rem;
  align-items: center;
  gap: 0.375rem;
  border-radius: 0.375rem;
  border: 1px solid var(--colors-a-020);
  margin-right: 0.625rem;

  span {
    color: rgba(20, 20, 22, 0.8);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    display: flex;
    margin-bottom: -0.25rem;
  }

  svg {
    display: flex;
  }

  &__0 {
    svg {
      rect {
        &:nth-child(1) {
          fill: var(--colors-e-100);
        }
      }
    }
  }
  &__1 {
    rect {
      &:nth-child(1) {
        fill: var(--colors-e-100);
      }
      &:nth-child(2) {
        fill: var(--colors-e-100);
      }
    }
  }
  &__2 {
    rect {
      fill: var(--colors-e-100);
    }
  }
}
