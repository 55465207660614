.body {
  --margin: 1rem;
  --padding: 1rem;

  @media (min-width: 48.0625em) {
    --margin: 1.5rem;
    --padding: 2rem;
  }

  background-color: var(--on-surface-a-100);
  border: 1px solid #eaeffe;
  box-shadow: 0px 20px 30px rgba(21, 23, 61, 0.07);
  border-radius: 16px;
  max-height: calc(100dvh - 2 * var(--margin));
  height: min-content;
  max-width: 1000px;
  width: 100%;
  outline: none;
  margin: var(--margin);
  padding: var(--padding);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

.wrapper {
  flex-grow: 1;
  overflow-y: auto;
  display: grid;
  gap: 1rem;
  padding-right: 0.5rem;
  margin-right: -0.5rem;
  padding-left: 3px;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px var(--on-surface-b-040);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colors-a-100);
  }
  @media (min-width: 48.0625em) {
    grid-template-columns: minmax(0, 1fr) minmax(280px, 0.59fr);
    gap: 2rem;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;

  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }
  }

  &__close {
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
    display: block;
    padding: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 48.0625em) {
    border-right: 1px solid var(--on-surface-b-010);
    padding-right: 2rem;
    order: 1;
  }

  .user {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-060);
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .payment {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    & .terms {
      &:global(.MuiTypography-root) {
        color: var(--on-surface-b-100);
        text-align: center;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      &__link {
        &:global(.MuiTypography-root) {
          color: var(--colors-a-100);
          font-weight: 700;
          text-decoration: none;
        }
      }
    }
  }
}

.course {
  border-bottom: 1px solid var(--on-surface-b-010);
  padding-bottom: 1.5rem;
  @media (min-width: 48.0625em) {
    order: 2;
    border-bottom: unset;
    padding-bottom: 0;
  }
  &__data {
    display: grid;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  &__image {
    max-width: 100%;
    width: 100%;
    height: auto;
    border-radius: 16px;
    display: flex;
    object-fit: cover;
  }

  .totalBlock {
    padding-top: 1.5rem;
    border-top: 1px solid var(--on-surface-b-010);
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  &__price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
  }

  .total {
    color: var(--on-surface-b-100);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-family: var(--proxima);
  }
}

.coupon {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--on-surface-b-010);
  & .form {
    display: flex;
    gap: 0.5rem;

    & :global(.MuiButtonBase-root) {
      margin-top: 1.15rem;
    }
  }
}

.couponDetails {
  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  &__discount {
    &:global(.MuiTypography-root) {
      margin-top: 0.375rem;
      color: var(--colors-b-100);
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  &__price {
    &:global(.MuiTypography-root) {
      margin-top: 0.2rem;
      color: var(--on-surface-b-100);
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      span {
        color: var(--on-surface-b-060);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        text-decoration: line-through;
      }
    }
  }
  &__date {
    &:global(.MuiTypography-root) {
      margin-top: 0.375rem;
      color: var(--on-surface-b-060);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  }
  &__info {
    &:global(.MuiTypography-root) {
      margin-top: 0.5rem;
      color: var(--on-surface-b-070, rgba(20, 20, 22, 0.7));
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

.plans {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal {
  max-width: min(calc(100% - 6px - 2em), 440px);
}

.lineThrough {
  text-decoration: line-through;
}
