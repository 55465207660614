.list {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 1rem;
}

.title {
  &:global(.MuiTypography-root) {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: normal;
    color: var(--on-surface-b-100);
  }
}

.header {
  display: flex;
  align-items: center;
  flex-grow: 1;
  &__title {
    &:global(.MuiTypography-root) {
      font-size: 1rem;
      line-height: normal;
      font-weight: 700;
      color: var(--on-surface-b-100);
    }
  }
}

.text {
  &:global(.MuiTypography-root) {
    font-size: 0.875rem;
    line-height: 1.5;
    color: var(--on-surface-b-070);
  }
}
