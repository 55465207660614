.grid {
  display: grid;
  gap: 0.5rem;
}

@container course-details-container (min-width: 48.0625em) {
  .grid {
    &:not(:last-child) {
      padding-bottom: 1.5rem;
      border-bottom: 1px solid var(--colors-a-010);
    }
  }
}
