@import "../../../assets/styles/mixins";

.body {
  max-width: 1440px;
  @include make-container($padding-x: 1.5rem);
  overflow-y: auto;
  max-height: 100dvh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 1rem;
  padding-bottom: 1rem;

  @media (max-width: calc(48em - 1px)) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.wrapper {
  padding: 56.25% 0 0 0;
  position: relative;
  border: 2px solid var(--on-surface-b-100);
  height: 0;
}

.video,
iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
