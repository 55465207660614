.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}

.innerContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.topBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 0 1rem;
  .logo {
    height: auto;
    width: 135px;
  }
  .closeBtn {
    all: unset;
    line-height: 0;
    cursor: pointer;
    svg {
      path {
        fill: var(--on-surface-a-100);
      }
    }
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem 1rem 1rem;
}

.authButton {
  button {
    width: 100%;
  }
}

.navIcon {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: var(--on-surface-a-010);
  transition: 0.2s ease;
  svg {
    path {
      transition: 0.2s ease;
    }
  }
}

.navList {
  list-style: none;
  margin: 0;
  padding: 0 0 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.mediaList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .itemText {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1rem;
    padding: 0;
    opacity: 0.7;
    color: var(--on-surface-a-100);
    transition: 0.2s ease;
  }
  &.active {
    .navIcon {
      background-color: var(--on-surface-a-100);
      svg {
        path {
          fill: var(--colors-a-200);
        }
      }
    }
    .itemText {
      opacity: 1;
    }
    .itemIndicator {
      opacity: 1;
    }
  }
  &:not(.noHover):hover {
    .navIcon {
      background-color: var(--on-surface-a-100);
      svg {
        path {
          fill: var(--colors-a-200);
        }
      }
    }
    .itemText {
      opacity: 1;
    }
    .itemIndicator {
      opacity: 1;
    }
  }
  &:hover {
    cursor: pointer;
    .itemText {
      opacity: 1;
    }
  }
}

.innerItem {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.navLink {
  width: 100%;
  text-decoration: none;
}

.itemIndicator {
  width: 4px;
  height: 30px;
  border-radius: 12px 0 0 12px;
  background-color: var(--on-surface-a-100);
  transition: 0.2s ease;
  opacity: 0;
}

.mediaIcon {
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid var(--on-surface-a-100);
  transition: 0.2s ease;
  cursor: pointer;
  svg {
    path: {
      transition: 0.2s ease;
    }
  }
  &:hover {
    background-color: var(--on-surface-a-100);
    svg {
      path {
        fill: var(--colors-a-200);
      }
    }
  }
}

.logoutBtn {
  button {
    color: var(--on-surface-a-100);
    border: 1px solid var(--on-surface-a-100);
    background: transparent;
    &:hover {
      color: var(--colors-a-200);
    }
  }
}

.userBox {
  padding: 0 1rem;
  a {
    padding: 0;
  }
}
