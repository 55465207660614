@mixin make-container($padding-x: $container-padding-x) {
  width: 100%;
  padding-right: $padding-x;
  padding-left: $padding-x;
  margin-right: auto;
  margin-left: auto;
}

$breakpoints: (
  "xs": 375px,
  "sm": 540px,
  "md": 768px,
  "lg": 1024px,
  "xl": 1440px,
);

@mixin media($size) {
  $breakpoint: map-get($breakpoints, $size);
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin section-padding {
  padding: 2rem 0;
  @include media("lg") {
    padding: 3.68rem 0;
  }
}

@mixin container {
  padding: 0 1rem;
  width: 100%;
  @include media("md") {
    padding: 0 1.5rem;
  }
  @include media("lg") {
    max-width: 1440px;
    margin: 0 auto;
  }
}
