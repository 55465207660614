.title {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-100);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.desktop {
  margin-left: -1rem;
  margin-right: -1rem;
  padding-bottom: 0.25rem;
  margin-bottom: -0.25rem;
  margin-top: -0.5rem;
  overflow-x: hidden;

  &__content {
    display: flex;
    gap: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.25rem;
    margin-bottom: -0.25rem;
    margin-top: 0.5rem;
    padding-top: 1rem;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 2px;
      border-radius: 1rem;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px var(--on-surface-b-040);
      box-shadow: inset 0 0 1px transparent;
      margin-left: 1rem;
      margin-right: 1rem;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--colors-a-100);
      border-radius: 1rem;
    }
  }

  @media (min-width: 48.0625em) {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 0;
    overflow-x: visible;

    &__content {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      margin-bottom: 0;
      padding-top: 0;
      overflow-x: visible;
    }
  }
}
