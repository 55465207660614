.link {
  width: max-content;
  &:global(.MuiTypography-root) {
    display: flex;
    align-items: center;
    color: var(--colors-a-100);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
    gap: 0.25rem;

    svg {
      display: flex;
      margin-top: -0.3125rem;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & .logo {
    display: none;

    @media (max-width: 40.0625rem) {
      display: flex;
      width: max-content;
      svg {
        display: flex;
        max-width: 8.4375rem;
        width: 100%;
        height: auto;
      }
    }
  }
}
