.wrapper {
  & .title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 0.25rem;
    }
  }
  & .text {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-090);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  & .actions {
    margin: 1.5rem auto 0;
    display: flex;
    flex-direction: column;
    max-width: 22.5rem;
    gap: 0.75rem;
    @media (min-width: 40.0625em) {
      flex-direction: row;
      margin: 1rem 0 0;
    }
    .button {
      width: 100%;
      @media (min-width: 40.0625em) {
        width: max-content;
      }
    }
  }
}
