.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
  margin: 0;
  padding: 0;
  list-style: none;
  column-gap: 2rem;
  row-gap: 2rem;
  flex-grow: 1;

  @media (min-width: 64.0625em) {
    column-gap: 2.25rem;
  }
}
