.wrapper {
  margin-right: -1rem;
  margin-left: -1rem;
  padding-bottom: 0.25rem;
  margin-bottom: -0.25rem;
  overflow-x: hidden;
}
.info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: 0.25rem;
  margin-bottom: -0.25rem;
  overflow-x: auto;
  @media (min-width: 48.0625em) {
    gap: 1rem;
  }

  &::-webkit-scrollbar {
    height: 2px;
    border-radius: 1rem;
  }
  &::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 1px var(--on-surface-b-040);
    box-shadow: inset 0 0 1px transparent;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--colors-a-100);
    border-radius: 1rem;
  }
}
