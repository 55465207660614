@import "../../../../assets/styles/typography";
@import "../../../../assets/styles/mixins";

.title {
  @include homeSectionHeading;
  text-align: center;
  &:global(.MuiTypography-root) {
    margin-bottom: 1rem;
  }
}

@include media("md") {
  .title {
    &:global(.MuiTypography-root) {
      margin-bottom: 2rem;
    }
  }
}

.carousel {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .button {
    margin: 0 auto;
  }
}

@include media("sm") {
  .carousel {
    .button {
      width: max-content;
    }
  }
}
