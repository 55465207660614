@font-face {
  font-family: "Biotif";
  src: local("Biotif-Black"),
    url("../fonts/Biotif/Biotif-Black.woff2") format("woff2"),
    url("../fonts/Biotif/Biotif-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Biotif";
  src: local("Biotif-Bold"),
    url("../fonts/Biotif/Biotif-Bold.woff2") format("woff2"),
    url("../fonts/Biotif/Biotif-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Biotif";
  src: local("Biotif-Regular"),
    url("../fonts/Biotif/Biotif-Regular.woff2") format("woff2"),
    url("../fonts/Biotif/Biotif-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova Black"), local("ProximaNova-Black"),
    url("../fonts/Proxima/ProximaNova-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova Bold"), local("ProximaNova-Bold"),
    url("../fonts/Proxima/ProximaNova-Bold.woff2") format("woff2"),
    url("../fonts/Proxima/ProximaNova-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova Semibold"), local("ProximaNova-Semibold"),
    url("../fonts/Proxima/ProximaNova-Semibold.woff2") format("woff2"),
    url("../fonts/Proxima/ProximaNova-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova Regular"), local("ProximaNova-Regular"),
    url("../fonts/Proxima/ProximaNova-Regular.woff2") format("woff2"),
    url("../fonts/Proxima/ProximaNova-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

:root {
  --biotif: Biotif, sans-serif;
  --proxima: Proxima Nova, sans-serif;
}
