.card {
  border-radius: 1rem;
  border: 1px solid var(--on-surface-b-005);
  background: var(--on-surface-a-100);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  text-decoration: none;
  transition: box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  &:hover {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 40.0625rem) {
    flex-direction: row;
    align-items: flex-start;
  }

  .image {
    background: var(--colors-a-010);
    overflow: hidden;
    border-radius: 0.75rem;
    aspect-ratio: 1;
    flex: 0 0 9.0625rem;
    height: max-content;

    img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .content {
    .title {
      &:global(.MuiTypography-root) {
        color: var(--colors-a-100);
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 0.06rem;
        text-align: center;
        @media (min-width: 40.0625rem) {
          text-align: left;
        }
      }
    }

    .specialization {
      &:global(.MuiTypography-root) {
        color: var(--on-surface-b-100);
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 0.75rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        svg {
          display: none;
        }

        span {
          color: var(--on-surface-b-060);
          font-size: 1.25rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        @media (min-width: 40.0625rem) {
          text-align: left;
          display: block;

          svg {
            display: inline-block;
            margin-left: 0.75rem;
            margin-right: 0.75rem;
            vertical-align: middle;
          }
        }
      }
    }

    .htmlDescription {
      div {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
    }
  }
}
