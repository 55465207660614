.backdrop {
  width: 100vw;
  height: 100dvh;
  z-index: 10000;
  background: #000;
  position: fixed;
  inset: 0;
  background: rgba(15, 17, 29, 0.2);
  display: grid;
  place-items: center;
}

.modal {
  width: min(calc(100vw - 32px), 472px);
  height: max-content;
  max-height: calc(100dvh - 32px);
  background-color: #fff;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .body {
    overflow-y: auto;
    flex-grow: 1;
    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px var(--on-surface-b-040);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--colors-a-100);
    }
  }

  .close {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    background-color: var(--on-surface-a-070);
    svg {
      display: flex;
      width: 100%;
      height: auto;
    }

    @media (min-width: 48em) {
      right: 24px;
      top: 24px;
    }
  }
}
.image {
  aspect-ratio: 16/9;
  border-radius: 16px;
  overflow: hidden;
  img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  @media (min-width: 48em) {
    padding: 32px;
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .title {
      color: var(--on-surface-b-100);
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      text-transform: uppercase;
      font-family: var(--proxima);
    }
    .description {
      color: rgba(20, 20, 22, 0.9);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .checkbox {
    & :global(.MuiCheckbox-root) {
      padding: 2px;
      margin-right: 4px;
    }
    &__text {
      color: var(--on-surface-b-100);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
