@import '../../../../assets/styles/typography';
@import '../../../../assets/styles/mixins';

.section {
  @include section-padding;
  background: linear-gradient(180deg, #ffffff 0%, #f0f0ff 100%);
}

.imageBox {
  img {
    width: 100%;
    height: 220px;
    object-fit: contain;
  }
}

.itemImage {
  width: 40px;
  height: 40px;
}

.title {
  &:global(.MuiTypography-root) {
    font-size: 24px;
    font-weight: 800;
    line-height: 29.23px;
    text-align: center;
    color: #a195ec;
    margin: 1.5rem 0 0.5rem 0;
  }
}

.description {
  &:global(.MuiTypography-root) {
    font-size: 1.25rem;
    font-weight: 800;
    line-height: 1.5rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  &.desk {
    display: none;
  }
}

.listItem {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.listTitle {
  &:global(.MuiTypography-root) {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.2rem;
  }
}

.listDescription {
  &:global(.MuiTypography-root) {
    font-size: 0.87rem;
    font-weight: 400;
    line-height: 1.05rem;
    color: rgba(20, 20, 22, 0.7);
  }
}

.ctaText {
  &:global(.MuiTypography-root) {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;
  }
}

.ctaText2 {
  &:global(.MuiTypography-root) {
    font-size: 1.25rem;
    font-weight: 800;
    line-height: 1.5rem;
    text-align: center;
  }
  &.desk {
    display: none;
  }
}

.actions {
  margin-top: 1rem;
}

.ctaButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

.ctaTextBox {
  margin-top: 1.5rem;
}

.ctaButton {
  background: #6200ea;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  animation: pulse 1.5s infinite;
  &:hover {
    background: #6200ea;
  }
}

@include media('sm') {
  .blocks {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .info {
    max-width: 75%;
  }
  .actions {
    display: flex;
    justify-content: center;
  }
  .ctaButton {
    width: max-content;
  }
}

@include media('md') {
  .info {
    max-width: 100%;
  }
  .imageBox {
    img {
      height: 100%;
    }
  }
  .blocks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
  }
  .title {
    &:global(.MuiTypography-root) {
      text-align: start;
      margin-top: 0;
    }
  }
  .description {
    &:global(.MuiTypography-root) {
      text-align: start;
    }
  }
  .ctaTextBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 2rem;
  }
}

@include media('lg') {
  .imageBox {
    img {
      height: 420px;
    }
  }
  .title {
    &:global(.MuiTypography-root) {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }
  .description {
    &:global(.MuiTypography-root) {
      font-size: 2.25rem;
      line-height: 2.6rem;
    }
    &.mob {
      display: none;
    }
    &.desk {
      display: inline-block;
    }
  }
  .listItem {
    margin-bottom: 2.37rem;
  }
  .listTitle {
    &:global(.MuiTypography-root) {
      font-size: 1.5rem;
      line-height: 1.8rem;
    }
  }
  .listDescription {
    &:global(.MuiTypography-root) {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  .ctaText {
    &:global(.MuiTypography-root) {
      font-size: 1.5rem;
      line-height: 1.8rem;
    }
  }
  .ctaText2 {
    &:global(.MuiTypography-root) {
      font-size: 1.5rem;
      line-height: 1.8rem;
    }
    &.mob {
      display: none;
    }
    &.desk {
      display: inline-block;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
