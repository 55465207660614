.card {
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  gap: 1rem;
  border-radius: 1rem;
  border: 2px solid var(--colors-a-020);
  background: var(--colors-a-005);
  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100, #141416);
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

.form {
  & :global(.hs-form-private) {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  & :global(.actions) {
    display: flex;
    justify-content: center;
    align-items: center;
    & :global(.hs-button) {
      display: flex;
      height: 48px;
      padding: 13px 24px;
      align-items: center;
      gap: 8px;
      border: none;
      border-radius: 12px;
      background-color: var(--colors-a-100);
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0.1) 100%
          ),
          var(--colors-a-100);
      }
      &:active {
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0.2) 100%
          ),
          var(--colors-a-100);
      }

      color: var(--on-surface-a-100);
      text-align: center;
      font-family: var(--proxima);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 135%;
      width: 100%;
    }
  }

  & :global(.hs-form-field) {
    display: flex;
    flex-direction: column;
    gap: 4px;
    label {
      color: var(--on-surface-b-070);
      font-family: var(--proxima);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    & :global(.input) {
      & :global(.hs-input) {
        padding: 12px 14px;
        border-radius: 12px;
        border: 2px solid var(--colors-a-020);
        background: var(--on-surface-a-100);
        color: var(--on-surface-b-100);
        font-family: var(--proxima);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        transition: 0.3s;
        outline: none;
        height: 48px;

        &:global(.invalid),
        &:global(.error) {
          border: 2px solid var(--colors-b-020);
        }

        &::placeholder {
          color: var(--on-surface-b-040);
          font-family: var(--proxima);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        &:focus {
          border: 2px solid var(--colors-a-100) !important;
        }
      }
    }

    & :global(.hs-error-msgs) {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-top: -4px;

      & :global(.hs-error-msg) {
        color: var(--colors-b-100);
      }
    }
  }
  & :global(.hs_error_rollup) {
    display: none;
  }
}
