.text {
  &:global(.MuiTypography-root) {
    display: flex;
    gap: 0.5rem;
    font-size: 1.25rem;
    align-items: center;

    svg {
      display: block;
      flex: 0 0 2rem;
    }

    span {
      color: var(--on-surface-b-060);
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: block;
    }
  }
}
