.root {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  padding: 0.25rem;
  border-radius: 12px;
  background-color: #3D40D50D;
  a {
    width: 100%;
    padding: 0.5rem 0;
    font-weight: 700;
    text-align: center;
    list-style: none;
    text-decoration: none;
    color: var(--on-surface-b-100, #141416);
    &.active {
      border-radius: 8px;
      background-color: var(--on-surface-a-100);
      box-shadow: 0px 5px 10px 0px #0000001A;
    }
  }
}
