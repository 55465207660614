.filters {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  & .search {
    &:global(.MuiFormControl-root) {
      margin-top: 0;
    }
    & :global(.MuiFormLabel-root) {
      display: none;
    }
    & :global(.MuiInputBase-input) {
      padding-left: 0 !important;
    }
    & :global(.MuiButtonBase-root) {
      padding: 0 0 0 0.75rem;
    }
  }

  & .tags {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;

    &__item {
      display: flex;
      align-items: center;
      padding: 0.3125rem 0.75rem 0.2125rem 0.75rem;
      border-radius: 62.5rem;
      background: var(--colors-a-010);
      color: var(--colors-a-100);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      cursor: pointer;
      white-space: nowrap;
      height: 1.5rem;
      svg {
        display: none;
      }

      &:hover {
        background: var(--colors-a-020);
      }

      &_selected {
        padding: 0.3125rem 0.5rem 0.2125rem 0.75rem;
        gap: 0.375rem;
        border: 1px solid var(--colors-a-100);

        svg {
          display: flex;
          margin-top: -0.125rem;
        }
      }
    }
  }
}
