@import "../../../../../assets/styles/mixins";

.section {
  padding: 2.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.container {
  @include container;
}

.actions {
  @include container;
  display: flex;
  justify-content: center;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.subHeading {
  font-size: 1rem;
  font-weight: 700;

  &:empty {
    display: none;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-left: 2rem;

  &__title {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 150%;
  }

  &__text {
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
  }

  li {
    &:empty {
      display: none;
    }
  }
}
.annotation {
  font-size: 1rem;
  font-weight: 700;
  color: #ffffffb2;
  &:empty {
    display: none;
  }
}

.imageBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(hsla(269, 66%, 64%, 0.8) 0 0 5rem);
  img {
    max-width: 100%;
    height: auto;
    display: flex;
  }
}

@include media("md") {
  .subHeading {
    font-size: 1.5rem;
  }
  .list {
    font-size: 1.25rem;
  }
}

@include media("lg") {
  .section {
    padding: 3.75rem 0;
  }
  .container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-areas: "right left";
    align-items: center;
  }
  .info {
    grid-area: left;
  }
  .imgBox {
    grid-area: right;
  }
  .subHeading {
    font-size: 1rem;
  }
  .list {
    font-size: 1rem;
  }
}

@include media("xl") {
  .subHeading {
    font-size: 1.5rem;
  }
  .list {
    font-size: 1.25rem;
  }
}
