.card {
  &__header {
    display: flex;
    gap: 0.675rem;
    margin-bottom: 0.625rem;
  }
}

.image {
  flex: 0 0 2.57275rem;
  height: 2.75rem;
  padding: 0.4rem;
  border-radius: 0.75rem;
  background: var(--colors-a-010);
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    left: 0;
  }

  svg {
    width: 100%;
    height: auto;
  }
}

.info {
  flex: 1;
}

.title {
  &:global(.MuiTypography-root) {
    color: var(--colors-a-100);
    text-decoration: none;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0.375rem;
  }
}

.subtitle {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-060);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
