.card {
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  gap: 1rem;
  border-radius: 1rem;
  background: var(--accent-gradient);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
  }

  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-a-100);
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  &__image {
    flex: 0 0 4.25rem;
    margin-top: -1rem;
    margin-right: -1rem;
    margin-bottom: -1.75rem;

    @media (min-width: 64.0625em) {
      flex: 0 0 5.5rem;
      margin-right: -0.75rem;
      margin-top: -0.75rem;
      margin-bottom: -2.75rem;
    }

    img {
      display: flex;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
}
