.navigation {
  display: flex;
  justify-content: center;
  gap: 0.875rem;
}

.slide {
  cursor: grab;
  display: flex;
  overflow: hidden;
  position: relative;

  @media (min-width: 35.0625em) {
    width: 28rem !important;
  }

  & .image {
    border-radius: 1.25rem;
    border: 5px solid var(--on-surface-a-100);
    box-shadow: 0px 20px 30px 0px rgba(21, 23, 61, 0.07);
    overflow: hidden;
    margin-bottom: 1rem;
    position: relative;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      left: 0;
      top: 0;
      background: transparent;
      transition: background 0.3s;
      position: absolute;
      z-index: 1;
    }

    & :global(.MuiIconButton-root) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 4rem;
      height: 4rem;
      opacity: 0;
      transition: opacity 0.3s;
      z-index: 2;

      svg {
        width: 2rem;
        height: auto;
      }
    }

    &:hover :global(.MuiIconButton-root) {
      opacity: 1;
    }

    &:hover {
      &:after {
        background: var(--colors-a-020);
      }
    }

    img {
      width: 100%;
      height: 100%;
      display: flex;
      object-fit: cover;
    }
  }

  & .name {
    display: flex;
    gap: 0.5rem;
    &:global(.MuiTypography-root) {
      span {
        color: var(--on-surface-a-100);
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5;
      }
    }

    svg {
      display: flex;
      filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.1));
    }
  }

  .description {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-a-070);
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
