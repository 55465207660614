@import './mixins';

@mixin heading {
  &:global(.MuiTypography-root) {
    font-family: Proxima Nova;
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.73rem;
    @include media('xl') {
      font-size: 2.5rem;
      line-height: 3.5rem;
    }
  }
}

@mixin heading3 {
  &:global(.MuiTypography-root) {
    font-family: Proxima Nova;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.7rem;
  }
}

@mixin homeSectionHeading {
  &:global(.MuiTypography-root) {
    font-family: Proxima Nova;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.82rem;
    @include media('lg') {
      font-size: 2.5rem;
      line-height: 3rem;
    }
  }
}
