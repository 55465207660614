@import '../../../../assets/styles/mixins';
@import '../../../../assets/styles/typography';

.section {
  @include section-padding;
  background-color: #f5f5fd;
}

.title {
  @include homeSectionHeading;
  &:global(.MuiTypography-root) {
    text-align: center;
    margin-bottom: 1rem;
  }
}

.listBox {
  display: flex;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.card {
  padding: 1rem;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0px 3px 8px 0px #042c5b1a;
}

.indexText {
  font-size: 3rem;
  line-height: 3.65rem;
  color: rgba(61, 64, 213, 0.4);
  &.item2 {
    color: rgba(61, 64, 213, 0.6);
  }
  &.item3 {
    color: rgba(61, 64, 213, 0.8);
  }
  &.item4 {
    color: rgba(61, 64, 213, 1);
  }
}

.itemTitle {
  &:global(.MuiTypography-root) {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.51rem;
    margin: 0.8rem 0;
  }
}

.progressBarBox {
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.progressBar {
  position: relative;
  height: 80%;
}

.points {
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.line {
  position: absolute;
  top: 0.5%;
  left: 10px;
  width: 4px;
  height: 99%;
  z-index: 1;
  background-color: #8788e5;
}

.ctaButton {
  margin-top: 1rem !important;
}

@include media('sm') {
  .title {
    @include homeSectionHeading;
    &:global(.MuiTypography-root) {
      margin-bottom: 1.87rem;
    }
  }
  .container {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .card {
    display: grid;
    grid-template-columns: 58px 1fr;
    align-items: center;
  }
  .cardInfo {
    padding-left: 1.5rem;
    margin-left: 1.2rem;
    border-left: 3px solid rgba(61, 64, 213, 0.6);
    border-radius: 2px;
  }
}

@include media('md') {
  .itemTitle {
    &:global(.MuiTypography-root) {
      margin: 0 0 0.75rem 0;
    }
  }
}

@include media('lg') {
  .card {
    grid-template-columns: 77px 1fr;
  }
  .indexText {
    font-size: 4rem;
    line-height: 4.8rem;
  }
  .itemTitle {
    &:global(.MuiTypography-root) {
      font-size: 1.5rem;
      line-height: 1.8rem;
    }
  }
}

@include media('xl') {
  .card {
    padding-right: 6rem;
  }
}
