.password {
  margin-bottom: 1.5rem;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 1rem;
  @media (min-width: 40.0625em) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.actions {
  width: 100%;

  @media (min-width: 35.0625em) {
    max-width: min-content;
    margin-left: auto;
  }
}

.delete {
  &__text {
    &:global(.MuiTypography-root) {
      margin-bottom: 1.5rem;
      color: var(--on-surface-b-060);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.modal {
  max-width: min(100% - 6px - 2em, 27.5rem);
}
