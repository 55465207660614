.card {
  &:global(.MuiCard-root) {
    padding: 1rem;
    border-radius: 1rem;
    background: var(--on-surface-a-100);
    box-shadow: 0px 20px 30px 0px rgba(21, 23, 61, 0.07);
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;

    @media (min-width: 30.0625em) {
      grid-template-columns: repeat(auto-fit, minmax(24.25rem, 1fr));
    }

    @media (min-width: 48.0625em) {
      padding: 1.5rem;
    }
  }
}
