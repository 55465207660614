.container {
  background-color: #fafbff;
  &:global(.MuiContainer-root) {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  &__content {
    &:global(.MuiContainer-root) {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }
}

.outlet {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  margin-bottom: 2.5rem;
  flex-grow: 1;
}
