.card {
  display: flex;
  gap: 0.75rem;
  overflow: hidden;
  &__image {
    flex: 0 0 2.25rem;
    height: 2.25rem;
    aspect-ratio: 1;
    padding: 0.4rem;
    border-radius: 0.75rem;
    background: var(--colors-a-010);
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &_white {
      background: var(--on-surface-a-100);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }
  &__content {
    flex: 0 0 1;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    overflow: hidden;
    min-width: 10.4375rem;
  }
  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-a-100);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      white-space: break-spaces;
    }
    &_dark {
      &:global(.MuiTypography-root) {
        color: var(--on-surface-b-100);
      }
    }
  }
  &__stats {
    display: flex;
    gap: 0.75rem;

    span {
      &:global(.MuiTypography-root) {
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;

        span {
          color: var(--on-surface-a-070);
        }
      }
    }

    &_dark {
      span {
        &:global(.MuiTypography-root) {
          span {
            color: var(--on-surface-b-070);
          }
        }
      }
    }
  }
}
