.container {
  container-type: inline-size;
  container-name: root-container;
  position: relative;
  flex-grow: 1;
  display: flex !important;
  flex-direction: column;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  color: var(--on-surface-b-100);
  text-align: center;
  font-family: var(--proxima);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.navigation {
  display: flex;
  border-radius: 12px;
  background: var(--colors-a-005);
  padding: 4px;

  .link {
    padding: 8px 12px;
    text-align: center;
    flex: 1 0 0;
    border-radius: 8px;
    text-decoration: none;
    color: var(--on-surface-b-100);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    &:global(.active) {
      background-color: #fff;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    }
  }
}

.courses {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(18.75rem, 1fr));
  position: relative;
  align-items: start;
}
