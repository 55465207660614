.title {
  &:global(.MuiTypography-root) {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .image {
      flex: 0 0 2.75rem;
      height: 2.75rem;
      aspect-ratio: 1;
      padding: 0.4rem;
      border-radius: 0.75rem;
      background: var(--colors-a-010);
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (min-width: 48.0625em) {
        flex: 0 0 4rem;
        height: 4rem;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      }

      svg {
        width: 100%;
        height: auto;
      }
    }
    span {
      color: var(--on-surface-b-100);
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      display: block;
    }

    @media (min-width: 48.0625em) {
      gap: 1rem;
    }
  }
}
