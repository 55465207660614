@import "../../../assets/styles/mixins";
@import "../../../assets/styles/typography";

.section {
  padding: 2.625rem 0;
  background-color: var(--colors-a-005);
  height: 100%;
  @media (min-width: 48em) {
    padding: 4rem 0;
  }
}

.title {
  @include heading;
  text-align: center;
  margin-bottom: 1rem;
}

.text {
  margin: 0;
  margin-bottom: 2rem;
  color: var(--on-surface-b-070);
  text-align: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
