.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & :global(.StripeElement) {
    iframe {
      position: relative;
    }
  }
}

@media (min-width: 768px) {
  .form {
    max-width: 428px;
  }
}
