.highlighted {
  background: var(--accent-gradient);
  .card__name {
    color: var(--on-surface-a-100);
  }
  .card__price {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-a-100);
    }
  }
  .card__period {
    color: var(--on-surface-a-040);
  }
  .card__access {
    color: var(--on-surface-a-100);
  }

  .card__features {
    .feature {
      &__icon {
        background-color: var(--on-surface-a-100);

        svg {
          path {
            stroke: var(--colors-a-100);
          }
        }
      }
      &__text {
        color: var(--on-surface-a-100);
      }
    }
  }
  .card__current {
    color: var(--on-surface-a-100);
  }

  .card__originalPrice {
    color: var(--on-surface-a-060);
  }
}
.regular {
  background-color: var(--on-surface-a-100);
  .card__name {
    color: var(--on-surface-b-060);
  }
  .card__price {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
    }
  }

  .card__period {
    color: var(--on-surface-b-040);
  }
  .card__access {
    color: var(--on-surface-b-060);
  }

  .card__features {
    .feature {
      &__icon {
        background-color: var(--colors-e-100);
      }
      &__text {
        color: var(--on-surface-b-100);
      }
    }
  }
  .card__current {
    color: var(--colors-a-100);
  }

  .card__originalPrice {
    color: var(--on-surface-b-060);
  }
}

.card {
  border-radius: 16px;
  border: 1px solid var(--on-surface-b-005);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;

  @media (min-width: 35em) {
    padding: 1.5rem;
  }

  &__icon {
    padding: 0.75rem;
    border-radius: 10px;
    border: 1px solid #e4e7ec;
    background-color: var(--on-surface-a-100);
    width: 3rem;
    height: 3rem;
    margin-bottom: 1rem;
  }
  &__info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
  }
  &__name {
    &:global(.MuiTypography-root) {
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }
  }
  &__heading {
    display: flex;
    gap: 0.625rem;
  }

  &__price {
    &:global(.MuiTypography-root) {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      @media (min-width: 35em) {
        font-size: 2rem;
      }
    }
    &_free {
      color: var(--on-surface-b-060);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &__originalPrice {
    overflow: hidden;
    font-weight: 400;
    text-decoration: line-through;
  }
  &__period {
    font-size: 1.25rem;
    font-weight: 600;
  }

  &__access {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    margin-bottom: 0.75rem;
  }

  &__features {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 0.75rem;
    flex-direction: column;

    .feature {
      display: flex;
      gap: 0.5rem;

      &__icon {
        display: flex;
        width: 16px;
        height: 16px;
        padding: 3px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 8px;
        background-color: var(--colors-e-100);

        svg {
          width: 100%;
          height: auto;
        }
      }
      &__text {
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  &__current {
    &:global(.MuiTypography-root) {
      display: flex;
      height: 48px;
      padding: 13px 24px;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  &__subscription_canceled {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.modal {
  max-width: min(calc(100% - 6px - 2em), 440px);
}
