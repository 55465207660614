@import "../../../../../assets/styles/mixins";

.section {
  padding: 2.5rem 0;
}

.container {
  @include container;
}

.list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &__item {
    display: flex;
    flex-direction: column;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.imageBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(hsla(269, 66%, 64%, 0.8) 0 0 5rem);
  img {
    max-width: 100%;
    height: auto;
    display: flex;
  }
}

.title {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 150%;
}

.text {
  font-size: 1rem;
  font-weight: 400;
  line-height: 150%;
}

.annotation {
  font-size: 1rem;
  font-weight: 700;
  color: #ffffffb2;
  &:empty {
    display: none;
  }
}

@include media("md") {
  .title {
    font-size: 1.75rem;
  }
  .text {
    font-size: 1.25rem;
  }
}

@include media("lg") {
  .section {
    padding: 3.75rem 0;
  }
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "left right";
    align-items: center;
  }
  .info {
    grid-area: left;
  }
  .imageBox {
    grid-area: right;
  }
  .title {
    font-size: 1.25rem;
  }
  .text {
    font-size: 1rem;
  }
}

@include media("xl") {
  .title {
    font-size: 1.75rem;
  }
  .text {
    font-size: 1.25rem;
  }
}
