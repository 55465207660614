.form,
.wrapper {
  display: flex;
  flex-direction: column;

  @media (max-width: 48em) {
    height: 100%;
  }
}

.form {
  gap: 1rem;
}

.wrapper {
  gap: 1.5rem;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 0.375rem;

  @media (max-width: 40.0625em) {
    margin-top: auto !important;
  }
}
