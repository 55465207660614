.badge {
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  cursor: pointer;
  position: relative;

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block;
  }

  &:hover,
  &:active {
    .tooltip {
      opacity: 1;
    }
  }
}

.modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__header {
    display: flex;
    gap: 0.62rem;
  }
  &__title {
    flex-grow: 1;
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }
  }
  &__closeBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    border: 0;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
  }

  &__image {
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 20.16669rem;
    width: 100%;
    margin: 0 auto;
  }
  &__descriptionTitle {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      text-align: center;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  &__descriptionText {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-090);
      text-align: center;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
  }
}

.tooltip {
  border-radius: 0.75rem;
  border: 1px solid rgba(61, 64, 213, 0.2);
  background: var(--on-surface-a-100);
  box-shadow: 0px 20px 30px 0px rgba(21, 23, 61, 0.07);
  padding: 0.75rem;
  position: absolute;
  right: -0.5rem;
  top: -0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  max-width: 100%;
  opacity: 0;
  transition: opacity 0.3s;

  &__title {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
    }
  }
  &__points {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-100);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  }
}
