.discount {
  border-radius: 2px;
  background-color: #a674ef;
  color: var(--on-surface-a-100);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  padding: 2px 4px;
  white-space: nowrap;
}
