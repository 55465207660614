.section {
  padding: 2rem 0;
  background: linear-gradient(101deg, #040532 0%, #4c00ac 100%);
  @media (min-width: 64em) {
    padding: 5rem 0;
  }
}

.content {
  display: grid;
  gap: 1.5rem;

  @media (min-width: 64em) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.info {
  display: flex;
  flex-direction: column;
  order: 2;

  @media (min-width: 64em) {
    order: 1;
  }
}

.title {
  color: var(--on-surface-a-100);
  font-style: normal;
  font-weight: 900;
  line-height: 120%;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-bottom: 16px;
  margin-top: 0;
  order: 1;
  white-space: collapse balance;
  text-align: center;

  @media (min-width: 48em) {
    font-size: 2.625rem;
    margin-bottom: 24px;
  }
  @media (min-width: 64em) {
    text-align: left;
  }
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 0;
  order: 4;

  @media (min-width: 48em) {
    gap: 12px;
    margin-bottom: 32px;
  }
  @media (min-width: 64em) {
    order: 2;
  }

  &__item {
    display: flex;
    gap: 12px;
  }

  &__check {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1.5px solid #2bad28;
    background: #2bad28;
    display: flex;
    width: 16px;
    height: 16px;
    padding: 3px;

    svg {
      width: 100%;
      height: auto;
      display: flex;
    }

    @media (min-width: 48em) {
      width: 24px;
      height: 24px;
      padding: 4.5px;
    }
  }
  &__text {
    color: var(--on-surface-a-100);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (min-width: 48em) {
      font-size: 1.25rem;
    }
  }
}

.description {
  margin: 0;
  color: var(--on-surface-a-080);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-bottom: 12px;
  order: 2;

  @media (min-width: 48em) {
    font-size: 1rem;
  }
  @media (min-width: 64em) {
    text-align: left;
    margin-bottom: 16px;
  }
}

.button {
  &:global(.MuiButton-root) {
    width: 100%;
    text-transform: uppercase;
    margin-bottom: 24px;
    order: 3;

    @media (min-width: 30em) {
      margin-inline: auto;
      width: max-content;
    }

    @media (min-width: 64em) {
      margin: 0;
    }
  }
}

.icon {
  margin-bottom: 16px;
  svg {
    width: 100%;
    height: auto;
  }
  @media (min-width: 64em) {
    margin-bottom: 24px;
  }
  &__desktop {
    display: none;
    svg {
      display: flex;
    }
    @media (min-width: 64em) {
      display: block;
    }
  }
  &__mobile {
    display: flex;

    @media (min-width: 64em) {
      display: none;
    }
  }
}

.media {
  place-self: center;
  max-width: 684px;
  width: 100%;
  padding: clamp(0.875rem, -0.136rem + 4.494vw, 1.875rem);
  position: relative;
  order: 1;

  @media (min-width: 44.75em) {
    padding: 30px;
  }
  @media (min-width: 64em) {
    padding: clamp(1.313rem, -0.072rem + 2.163vw, 1.875rem);
    order: 2;
  }

  svg {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }

  .player {
    position: relative;
    padding-top: 56.25%;
    height: 0;
    overflow: hidden;
  }
  .poster {
    z-index: 1;
  }
  img,
  video,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
  }
}
