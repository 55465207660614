.wrapper {
  margin-top: 2rem;
}

.top {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.navigation {
  display: flex;
  justify-content: center;
  gap: 0.875rem;
}

.swiper {
  &:global(.swiper) {
    padding-bottom: 0.5rem;
    padding-left: 0.25rem;
    padding-top: 0.125rem;
    margin-bottom: -0.5rem;
    margin-left: -0.25rem;
    margin-top: -0.125rem;
    margin-right: -1rem;
  }
}

.slide {
  width: 20rem !important;
  height: 11.125rem !important;
  cursor: pointer;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  border-radius: 1.25rem;
  overflow: hidden;
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(../../../../assets/images/youtube-red.svg);
    background-repeat: no-repeat;
    background-size: 4rem;
    background-position: center;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s;

    border-radius: 1.25rem;
    background-color: var(--colors-a-020);
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  img {
    width: 100%;
    height: 100%;
    display: flex;
    object-fit: cover;
  }
}

@container course-details-container (min-width: 35.0625em) {
  .wrapper {
    margin-top: 2.5rem;
  }
  .carousel {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 6.25rem;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.9) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
    }
  }
  .swiper {
    &:global(.swiper) {
      margin-right: 0;
    }
  }

  .slide {
    width: 28rem !important;
    height: 15.5625rem !important;
  }
}
