.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @media (max-width: 48em) {
    height: 100%;
  }
}

.title {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-100);
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.text {
  &:global(.MuiTypography-root) {
    color: var(--on-surface-b-060);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    span {
      color: var(--on-surface-b-100);
      font-weight: 700;
    }
  }
}

.button {
  @media (max-width: 40.0625em) {
    margin-top: auto !important;
  }
}
