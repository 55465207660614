.actions {
  &:global(.MuiCardActions-root) {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-top: 0.75rem;

    & > * {
      width: 100%;
      margin: 0 !important;
    }

    @media (min-width: 30.0625em) {
      flex-direction: row;
      flex-wrap: wrap;
      & > * {
        width: unset;
      }
    }

    @media (min-width: 40.0625em) {
      margin-top: 0;
    }
  }
}
