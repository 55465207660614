@import '../../../../assets/styles/mixins';

.footer {
  background-color: var(--on-surface-b-100);
  container-type: inline-size;
  container-name: footer-container;

  &.promo {
    background: linear-gradient(180deg, #040532, #02000d);
  }

  @container footer-container (max-width: 30em) {
    .menu {
      grid-template-columns: 1fr;
      &__item {
        padding-right: 0;
        padding-bottom: 1.125rem;
        border-right: none;
        border-bottom: 1px solid var(--on-surface-a-010);
      }
    }
  }

  @container footer-container (min-width: 56.25em) {
    .body {
      padding-top: clamp(1.75rem, 0.3214rem + 2.9762vw, 3rem);
      grid-template-columns: minmax(0, 0.75fr) minmax(0, 1fr);
    }
  }
}

.body {
  padding-bottom: 4.5rem;
  grid-template-columns: 1fr;
  padding-top: 3rem;
  display: grid !important;
  gap: clamp(1.125rem, 0.125rem + 2.0833vw, 2rem);
}

.menu {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: clamp(1.125rem, 0.125rem + 2.0833vw, 2rem);

  &__item,
  &__contacts {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    svg,
    a {
      display: flex;
    }
  }

  &__item {
    padding-right: clamp(1.125rem, 0.125rem + 2.0833vw, 2rem);
    border-right: 1px solid var(--on-surface-a-010);
  }

  &__link {
    text-decoration: none;
    white-space: nowrap;
  }
}
