@import '../../../../assets/styles/mixins';

.container {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
}

.title {
  color: var(--on-surface-b-100);
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 1.5rem 0 1rem 0;
  font-family: var(--biotif);
}

.date,
.tag,
.author {
  color: var(--on-surface-b-060);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0.5rem;
}

.date {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.author {
  span {
    font-weight: 700;
  }
}

.markdown {
  h2 {
    color: var(--on-surface-b-090);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-family: var(--biotif);
    scroll-margin-block-start: var(--header-height);
    margin: 2rem 0 1rem 0;
  }

  h3 {
    color: var(--on-surface-b-090);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-family: var(--biotif);
    margin: 1.6rem 0 1rem 0;
  }

  h4 {
    color: var(--on-surface-b-090);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-family: var(--biotif);
    margin: 1.2rem 0 1rem 0;
  }

  p {
    color: var(--on-surface-b-090);
    font-family: var(--proxima);
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
    margin: 1rem 0;
  }

  a {
    color: var(--colors-a-100);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-decoration-line: underline;
    cursor: pointer;
  }

  ul,
  ol {
    padding-left: 1.5rem;
    margin: 1rem 0;
    li {
      color: var(--on-surface-b-090);
      font-family: var(--proxima);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }

  strong {
    font-weight: 700;
  }

  img {
    height: auto;
    width: 100%;
    max-width: 500px;
    border-radius: 8px;
  }

  p:has(img) {
    display: flex;
    justify-content: center;
  }

  /* Quote */
  blockquote {
    margin: 1rem 0;
    background: var(--colors-a-005);
    border: 2px solid var(--colors-a-020);
    border-radius: 1rem;
    padding: 1rem;
    width: 100%;
  }

  blockquote {
    h2,
    h3,
    h4 {
      margin: 0;
    }
  }

  blockquote :first-child {
    margin-top: 0;
  }

  blockquote :last-child {
    margin-bottom: 0;
  }

  /* Inline code */
  p code,
  li code,
  div code {
    font-family: 'Source Code Pro', monospace;
    font-weight: 600;
    font-size: 16px;
    padding: 0 4px;
  }

  /* Code block */
  pre {
    position: relative;
    margin: 16px 0;
    padding: 16px;
    border-radius: 8px;
    overflow: auto;
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
  }

  pre code {
    font-weight: 400;
    line-height: 150%;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
}

@include media('md') {
  .title {
    font-size: 2.5rem;
  }
}

@include media('lg') {
  .content {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 21.4375rem;
    gap: 2rem;
  }
  .sidebar {
    width: 100%;
    height: min-content;
    position: sticky;
    top: var(--header-height);
  }
}

@include media('xl') {
  .content {
    grid-template-columns: minmax(0, 1fr) 25.5rem;
  }
}
