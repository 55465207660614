.listItem {
  display: flex;
  padding-left: 0px;
  list-style: none;
  margin: 0px 0 0.5rem 0;
  &:last-child {
    margin: 0rem;
  }
}

.listItemBullet {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #2bad28;
  margin: 0.25rem 0.5rem 0 0;
}

.itemText {
  &:global(.MuiTypography-root) {
    font-size: 0.87rem;
    font-weight: 400;
    line-height: 1rem;
    color: rgba(20, 20, 22, 0.7);
  }
}

@media (min-width: 1440px) {
  .itemText {
    &:global(.MuiTypography-root) {
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }
  .listItemBullet {
    margin: 0.35rem 0.5rem 0 0;
  }
}
