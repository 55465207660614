.card {
  &__image {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: center;
    svg {
      display: flex;
      filter: drop-shadow(0px 4px 20px #d3d2f1);
    }
  }

  &__title {
    &:global(.MuiTypography-root) {
      color: #1d293f;
      text-align: center;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 0.1875rem;
    }
  }

  &__text {
    &:global(.MuiTypography-root) {
      color: var(--on-surface-b-060);
      text-align: center;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
  }
}
