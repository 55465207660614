.media {
  &:global(.MuiCardMedia-root) {
    position: relative;
    border-radius: 1.5rem;
    overflow: hidden;
    display: flex;
    background-color: var(--on-surface-a-100);
    border: 8px solid var(--colors-a-100);
    place-self: center;

    img {
      display: flex;
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
      object-fit: cover;
    }

    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 5.5rem;
      height: 5.5rem;
    }
  }
}
