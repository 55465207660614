@import '../../../../../assets/styles/mixins';

.section {
  padding: 2.5rem 0;
}

.container {
  @include container;
}

.heading {
  text-align: center;
}

.list {
  margin: 1.5rem 0;
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  flex-grow: 1;
  border-radius: 1.25rem;
  border: 2px solid hsla(0, 0%, 100%, 0.2);
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0) 0%,
    hsla(0, 0%, 100%, 0.1) 100%
  );
  margin-bottom: 1.5rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.icon {
  width: 72px;
  aspect-ratio: 1;
  align-self: center;
}

.text {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  color: hsl(0, 0%, 100%);
  text-align: center;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 150%;
}

@include media('sm') {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .card {
    max-width: 330px;
  }
}

@include media('md') {
  .list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
  }
  .card {
    margin-bottom: 0;
  }
}

@include media('lg') {
  .section {
    padding: 3.75rem 0;
  }
}

@include media('xl') {
  .list {
    grid-template-columns: repeat(4, 1fr);
  }
}
