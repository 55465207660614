.swiper {
  padding: 24px 0 24px 16px;
  margin: -24px -16px;

  @media (min-width: 48em) {
    padding: 24px 0 24px 24px;
    margin: -24px;
  }

  @media (min-width: 90em) {
    padding: 24px 24px;
  }
}

.slide {
  height: auto;
  width: 300px;

  @media (min-width: 48em) {
    width: 330px;
  }
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
